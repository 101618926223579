/*@import url('https://fonts.googleapis.com/css?family=Inter');*/

body {
  margin: 0;
  padding: 0;
  font-family: "Inter var", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  /* font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif; */
  background-color: #F5F7F8;
  /*overflow: hidden;*/
}

@font-face {
  font-family: Inter var;
  src: url(Inter-VariableFont_slnt_wght.ttf);
}

.bodyWrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto -40px;
  /* the bottom margin is the negative value of the footer's height */
}

.pageheader {
  color: #5a5a5a;
  font-size: 22px;
  /*padding-top: 20px;
  padding-bottom: 20px;*/
}

.subheader {
  font-weight: bold;
  font-size: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.lineseparator {
  padding-top: 0em;
  height: 1px;
  color: #eee;
  border: none;
  background-color: #acacac;
  size: 10;
  margin-bottom: 1px;
}

hr.thin {
  height: 1px;
  color: #eee;
  border: none;
  background-color: #acacac;
  size: 10;
}

/* .wrapper {
  padding-left: 0.5em;
  position: relative;
} */

.fileupload-wrapper>li {
  padding: 0.5em !important;
}

.fileupload-wrapper ul {
  margin-left: 3px !important;
  margin-right: 10px !important;
  margin-bottom: 0px !important;
}

.file_upload_wrap ul {
  /* margin-left: 3px !important;
  margin-right: 10px !important;
  margin-bottom: 0px !important; */
  margin: 0px !important;
  padding-left: 0px !important;
}

svg[aria-label="Trash"] {
  stroke: red !important;
  width: 19px;
}

#lic_inst_byPac ul {
  margin-left: 3px !important;
  margin-right: 10px !important;
  margin-bottom: 0px !important;
}

/* Font icon mappings */

.breadcrumb {
  font-size: 14px;
  font-weight: 400;
  background-color: transparent;
  border-radius: 0;
  margin-bottom: 5px;
  padding: 0;
  /* margin-left: 10px; */
  margin-top: 5px;
}

.breadcrumb>li {
  display: inline;
}

.breadcrumb>li>span {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.56);
  text-decoration: none;
}

/* .breadcrumb > li > a:focus {
    color: #0073E7;
    text-decoration: underline;
    font-weight: 400; }
  .breadcrumb > li > a:hover {
    color: #0073E7;
    text-decoration: none;
    font-weight: 400; } */
.breadcrumb>.active,
.breadcrumb> :active {
  color: #000000 !important;
  text-decoration: none !important;
  font-weight: 700 !important;
}

.breadcrumb>li+li::before {
  content: url(../svg/formnext.svg);
  font-weight: 400;
  color: #000000;
  margin: 0 8px;
}

.panel {
  margin-bottom: 16px;
  background-color: #FFFFFF;
  border: 1px solid white;
  border-radius: 0;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.15);
}

h2.panel-title {
  margin: 0px !important;
}

.panel .panel-body {
  padding: 10px;
  background-color: #FFFFFF;
}

.panel.panel-default {
  border-color: rgba(0, 0, 0, 0.12);
}

.panel.panel-primary {
  border-color: rgba(0, 0, 0, 0.12);
  border-top: 5px solid #0073E7;
}

.panel.panel-default .panel-title,
.panel.panel-primary .panel-title {
  color: #323435;
  font-weight: bold !important;
  margin-bottom: 10px !important;
  /* text-transform: capitalize !important; */
}

.panel .panel-heading {
  padding: 10px;
  background-color: #FFFFFF;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.panel .panel-footer.panel-button-footer {
  text-align: right;
  bottom: 0;
  width: 100%;
}

.panel.panel-default .panel-footer {
  border-color: rgba(0, 0, 0, 0.12);
  background-color: #FFFFFF;
}

.panel-footer {
  border-top: 1px solid #ddd;
}

.spinLabelLarge {
  font-size: 2em !important;
}

.spinLableSmall {
  font-size: 1em !important;
}

/* .modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
} */
.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}

.alert-modal-main {
  display: flex;
  width: 40%;
}

.modal-main {
  position: fixed;
  background: white;
  /* min-width: 50%;
  max-width: 90%;
  width: 100%; */
  height: auto;
  /* max-height: 90%; */
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display-block {
  display: flex !important;
}

.display-nonflex {
  display: block !important;
}

.display-none {
  display: none !important;
}

.lic-detail-table {
  table-layout: fixed;
  width: 100%;
  max-width: 100%;
  margin-bottom: 5px;
}

.table-bordered>thead>tr>th,
.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>tbody>tr>td,
.table-bordered>tfoot>tr>td {
  padding: 3px;
  word-wrap: break-word;
  vertical-align: top;
  border: 1px solid #ddd;
  font-size: 0.8rem !important;
}

.lic-detail-head {
  font-weight: bold;
  color: #696969;
  background-color: #f4f4f4;
}

.userLabelDiv {
  width: 9%;
  float: left;
  color: rgba(0, 0, 0, 0.56);
}

.userInput {
  width: 40%;
}

.userInputDiv {
  width: 80%;
  float: left;
  padding: 2px;
}

.input-error {
  border: 2px solid #E5004C !important;
}

/* .bodyfont table {
  table-layout: fixed !important;
  border-color: transparent !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5) !important;
} */

/* .bodyfont table>thead>tr>th,
.bodyfont table>tbody>tr>th,
.bodyfont table>tfoot>tr>th,
.bodyfont table>thead>tr>td,
.bodyfont table>tbody>tr>td,
.bodyfont table>tfoot>tr>td {
  word-wrap: break-word;
  font-size: 0.95rem !important;
  padding: 5px !important;
} */

.userBackground {
  /* background: #F5F7F8; */
  /* font-size: 16px;
  height: 30px; */
}

.updateUserDiv {
  width: 49%;
  float: left;
  display: flex;
  flex-grow: 1;
  /* padding-left: 5px; */
  flex-direction: column;
  box-sizing: border-box;
}

.updateUserDiv:first-child {
  padding-right: 10px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.updateUserDiv:nth-child(2) {
  padding-left: 10px;
}

.rightDiv {
  width: 50% !important;
  float: right !important;
}

.groupList {
  height: 150px;
  width: 80%;
  border: 1px solid #b4b8b4
}

.manageGroupDiv {
  float: left;
  width: 10%;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

.manageUserDiv {
  float: left;
  width: 10%;
  border-bottom: 1px solid black;
  text-align: center;
}

.login-div {
  height: 90% !important;
}

.login-footer-div {
  position: fixed !important;
  width: 100% !important;
}

.grommetux-tab__label::after {
  margin-top: 9px !important;
}

#resv_member_tabs_div_id .grommetux-tab__label::after {
  margin-top: 0px !important;
}

#resv_member_tabs_div_id .grommetux-button {
  text-transform: unset !important;
}

.opacquemodal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(252, 252, 252, 1);
}

.hp-color-white {
  color: white;
}

table.abouttable {
  width: 100%;
  background-color: white;
  padding: 0;
  border: 0;
  margin-bottom: 0.5em !important;
  background-color: white;
}

td#aboutPagetitle1 {
  font-size: 20px;
  color: #333333;
  padding-left: 10px;
  padding-top: 10px;

  text-align: left;
  border-style: none;
  vertical-align: text-top;
  text-align: left;
}

td.aboutPage_title2 {
  font-size: 14px;
  color: #333333;
  line-height: 26.67px;
  padding-left: 20px;
  vertical-align: text-top;
  text-align: left;
  border-style: none;
}

td.aboutPage_title3 {
  font-size: 14.67px;
  color: #333333;
  line-height: 26.67px;
  padding-left: 20px;
  vertical-align: text-top;
  text-align: left;
  padding-bottom: 20px;
  border-style: none;
}

td.linecss {
  height: 1px;
  width: 100%;
  padding: 0 !important;
  border-top: 1px solid #c2bfbf;
}



td.aboutPage_title5 {
  font-size: 13.34px;
  line-height: 26.67px;
  font-weight: normal;
  color: #333333;
  padding-left: 20px;
  text-align: left;
}

td.aboutPage_title6 {
  font-size: 13.34px;
  line-height: 26.67px;
  font-weight: normal;
  color: #333333;
  padding-bottom: 20px;
  padding-left: 20px;
  text-align: left;
}



#aboultlink {
  color: #FBF8EF;
}

.header {
  font-size: 25px;
  background-color: #0096D4;
  color: #fbfbfb;
  vertical-align: middle;
  height: 40px;
}

.header1 {
  font-weight: bold;
  font-size: 25px !important;
  background-color: #fff;
  padding: 2px;
  padding-left: 2%;
  background-repeat: no-repeat;
  color: #333;
  vertical-align: middle;
  text-align: left;
}

.header2 {
  width: 15%;
  background-color: #fff;
  background-repeat: no-repeat;
  color: #333;
  vertical-align: middle;
  text-align: left;
  padding-right: 10px;
}

td#versionInfo {
  line-height: 26.67px;
  font-size: 15.34px;
  color: #333333;
  padding-left: 2%;
  vertical-align: text-top;
  text-align: left;
}

td#buildNumberInfo {
  line-height: 26.67px;
  font-size: 15.34px;
  color: #333333;
  padding-right: 20px;
  vertical-align: text-top;
  text-align: right;
}

.tabActive {
  text-transform: uppercase;
  padding: 0px 2px !important
}

.tabInActive {
  text-transform: uppercase;
  padding: 0px 3px !important
}

.tabActive .grommetux-tab__label::after {
  margin-top: -2px !important;
}

.grommetux-form-field--error .grommetux-form-field__contents {
  height: 5em !important;
}

.grommetux-form-field--error .grommetux-form-field__contents .grommetux-form-field__error {
  bottom: unset !important;
}

.buymoreError .grommetux-form-field__error {
  left: 0% !important;
}

.grommetux-form-field__error {
  color: #E5004C;
  line-height: 20px !important;
  bottom: 1px !important;
}

.form-title-padding {
  padding: 0px !important;
  height: 250px;
}

#ImplicitFeatureModal .modal-main {
  min-width: 38%;
  max-width: 90%;
  width: 38%;
}

#ImplicitFeatureModal .grommetux-button.modalFootInstallBtn {
  margin-right: 10px;
}

#conf_main_form_id span.grommetux-radio-button__label {
  white-space: nowrap;
}

form#create_pool_form_id2 {
  overflow-y: scroll;
  max-height: 54vh;
}

select.customSelect {
  padding: 0px !important;
}

div#modalDiv.reset_password h2#modal_title {
  text-transform: unset !important;
}

.flexBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flexBox .floatLeft {
  display: flex;
  justify-content: flex-start;
}

.floatRight {
  /* display: flex;
  justify-content: flex-end;
  margin-right: 6px; */
  /*margin-left: auto;*/
}

.license-view-buttons {
  /* float: left; */
  /* padding-top: 6px; */
  /* margin-right: 6px; */
}

.umUserGroupNameDiv {
  float: left;
  border: 1px solid #cccccc;
}

#user-groud-details-tab {
  float: left;
  width: 100%;
}

#user-groud-details-tab ul {
  margin-bottom: 0px !important;
}

#user-groud-details-tab ul li {
  margin-right: 20px;
}

.password_icon .grommetux-button__icon {
  padding: 0px !important;
  margin-right: -15px;
}

.instalLic-btn-wrap>div {
  /* float: left;
  margin-right: 10px; */
}

/* .alert-modal-main {
  display: flex;
  position: fixed;
  background: white;
  width: 40%;
  height: auto;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.panel .alert-panel-body {
  padding: 10px 10px;
  background-color: #FFFFFF;
  /*text-align: center;*/
  vertical-align: middle;
  /*height:100%;*/
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /*position: relative;*/
  box-sizing: border-box;
}

.panel .alert-panel-footer.panel-button-footer {
  border-top: 1px solid #ddd;
  padding: 8px 15px;
  text-align: right;
  bottom: 0;
  /*position: absolute;*/
  width: 100%;
  left: 0%;
  box-sizing: border-box;
}

.vertical-align {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 13em;
}

.draggable {
  display: inline-block !important;
  cursor: move;
  min-width: 10em;
}

.dragContainer {
  padding: 10px 10px;
  border: 2px dashed #323435;
}

h1.collapse::before,
span.collapse::before {
  padding-right: 5px;
  /* content: url(../svg/formnext.svg); */
  content: "►";
}

h1.expand::before,
span.expand::before {
  padding-right: 5px;
  /* content: url(../svg/formdown.svg); */
  content: "▼";
}

.pivot-cell {
  font-size: 0.82rem !important;
  text-transform: none !important;
  letter-spacing: 0.25px !important;
  padding: 0px !important;
  margin: 0px !important;
  letter-spacing: 0.2px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

table.pvtTable tbody tr th.pvtRowLabel {
  vertical-align: top !important;
  white-space: nowrap !important;
  background-color: #fff !important;
}

table.pvtTable tr .colhide,
table.pvtTable .rowhide {
  display: none;
}

table.pvtTable thead tr th,
table.pvtTable tbody tr th {
  background-color: #e6EEEE;
  border: 1px solid #CDCDCD;
  font-size: 8pt;
  padding: 5px;
}

.pivot-spin-div {
  display: 'flex';
  margin-left: "48%";
  line-height: "27px";
  margin-top: "9px";
}

form#cttenant_form_id2 #lic_view_grid {
  max-height: 50vh;
  overflow: auto;
  border: 1px solid #DCDEDF;
}

.filter-values {
  padding: 0px !important;
}

.filter-values table {
  width: auto;
}

.filter-dropdown {
  position: fixed;
  z-index: 10;
  border-radius: 0px;
  overflow: auto;
  background-color: #FFFFFF;
  /* border: solid #ededed;
  -webkit-box-shadow: 2px 2px rgb(0 0 0 / 7%);
  box-shadow: 2px 2px rgb(0 0 0 / 7%); */
}

.filter-select {
  background-color: rgba(0, 115, 231, 0.1);
  box-shadow: 2px 2px rgba(0, 0, 0, .15) !important;
}

.filter-unselect {
  background-color: transparent;
  box-shadow: 2px 2px rgba(0, 0, 0, .15) !important;
}

.context-menu {
  padding: 5px;
  background-color: #FFFFFF;
  border: solid #ededed;
  -webkit-box-shadow: 2px 2px rgb(0 0 0 / 7%);
  box-shadow: 2px 2px rgb(0 0 0 / 7%);
}

.grommetux-form-field-nozindex--error {
  min-height: 72px;
  height: 6em !important;
  bottom: unset !important;
  border-color: #E5004C;
}

.grommetux-form-field-nozindex--error .grommetux-form-field__error {
  color: #E5004C;
  display: block;
  text-align: center;
  font-size: 14px;
  position: absolute;
  bottom: 10px;
  /* left: 33%; */
}

.grommetux-form-field-nozindex--error .grommetux-form-field__error:before {
  content: '';
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxjaXJjbGUgc3R5bGU9ImZpbGw6I0U1MDA0QzsiIGN4PSI4IiBjeT0iOCIgcj0iOCIvPg0KPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik05LjQsOGwyLjMtMi4zYzAuNC0wLjQsMC40LTEsMC0xLjRzLTEtMC40LTEuNCwwTDgsNi42TDUuNyw0LjNjLTAuNC0wLjQtMS0wLjQtMS40LDBzLTAuNCwxLDAsMS40DQoJTDYuNiw4bC0yLjMsMi4zYy0wLjQsMC40LTAuNCwxLDAsMS40QzQuNSwxMS45LDQuNywxMiw1LDEyczAuNS0wLjEsMC43LTAuM0w4LDkuNGwyLjMsMi4zYzAuMiwwLjIsMC41LDAuMywwLjcsMC4zczAuNS0wLjEsMC43LTAuMw0KCWMwLjQtMC40LDAuNC0xLDAtMS40TDkuNCw4eiIvPg0KPC9zdmc+DQo=);
  display: inline-block;
  height: 14px;
  width: 14px;
  margin-right: 5px;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.grommetux-form-field__error:before {
  content: '';
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxjaXJjbGUgc3R5bGU9ImZpbGw6I0U1MDA0QzsiIGN4PSI4IiBjeT0iOCIgcj0iOCIvPg0KPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik05LjQsOGwyLjMtMi4zYzAuNC0wLjQsMC40LTEsMC0xLjRzLTEtMC40LTEuNCwwTDgsNi42TDUuNyw0LjNjLTAuNC0wLjQtMS0wLjQtMS40LDBzLTAuNCwxLDAsMS40DQoJTDYuNiw4bC0yLjMsMi4zYy0wLjQsMC40LTAuNCwxLDAsMS40QzQuNSwxMS45LDQuNywxMiw1LDEyczAuNS0wLjEsMC43LTAuM0w4LDkuNGwyLjMsMi4zYzAuMiwwLjIsMC41LDAuMywwLjcsMC4zczAuNS0wLjEsMC43LTAuMw0KCWMwLjQtMC40LDAuNC0xLDAtMS40TDkuNCw4eiIvPg0KPC9zdmc+DQo=);
  display: inline-block;
  height: 14px;
  width: 14px;
  margin-right: 5px;
  vertical-align: middle;
  background-repeat: no-repeat;
}

#lic_view_grid input.grommetux-text-input.grommetux-input.errorInput {
  border-bottom: 1px solid red;
}

.errorNotification {
  position: absolute;
  right: 35px;
  padding: 10px 20px;
  color: red;
  border-radius: 10px;
}

.successNotification {
  position: absolute;
  right: 35px;
  padding: 10px 20px;
  color: green;
  border-radius: 10px;
}

.page_header_wrap {
  display: flex;
  justify-content: flex-end;
}

.page_header_item {
  padding: 15px 15px 0 0;
}

.password_edit_icon {
  /* position: absolute; */
  padding: 5px;
  cursor: pointer;
  width: 24px;
}

input.installTabInput {
  width: 100%;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 1px solid #6d6d6d !important;
  border-top: none !important;
}

input.installTabInput.inputError {
  border-bottom: 1px solid #ff0000 !important;
}

input[type="password"]:disabled {
  background: #dddddd !important;
}

.pivot-table-row-header {
  font-weight: bold;
  vertical-align: middle;
  border-color: #bdbec0;
  border-style: solid;
  border-width: 0 0 1px 0;
  padding: 5px;
  font-size: 0.82rem;
  text-align: start !important;
  letter-spacing: normal;
  color: #4c4e50;
  border-collapse: collapse;
  position: relative;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.installTabInput.inputError span {
  font-size: 12px;
  color: red;
  clear: both;
  display: block;
}

.installTabInput span {
  display: none;
}

.panel .modal-panel-body {
  padding: 10px 10px;
  background-color: #FFFFFF;
}

.select-error {
  border-bottom: 2px solid #E5004C !important;
}

.customTextInput label.grommetux-form-field__label {
  width: 70% !important;
}

.customTextInput span.grommetux-form-field__error {
  left: 70% !important;
}

.OtLoadingSpinner {
  -webkit-animation: pulse;
  animation: pulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  width: 100px;
  height: 100px;
  margin: auto;
  border-radius: 0;
  box-shadow: none;
  /*0px 0px 10px 10px #fcfcfc;*/
  background-color: transparent;
  background-image: url(../img/OT_Spinner_Large.gif) !important;
}

.OtLoadingSpinnerSmall {
  -webkit-animation: pulse;
  animation: pulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  width: 30px;
  height: 30px;
  border-radius: 0;
  box-shadow: none;
  /*0px 0px 10px 10px #fcfcfc;*/
  background-color: transparent;
  content: url(../img/OT_Spinner_Large.gif);
}

.masthead-bg-color {
  background-image: linear-gradient(90deg, #19224a 0, #222e61 25%, #23346a 65%, #146693 90%, #088cb2);
  padding-right: 20px !important;
  padding-left: 20px !important;
}

#login_Sec {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #e7a8a8ee; */
  /* background: linear-gradient(90deg, #19224a 0, #222e61 25%, #23346a 65%, #146693 90%, #088cb2); */
  /* background: radial-gradient(47.55% 72.72% at -8.26% 38.8%, rgba(0, 76, 191, 1) 0%, rgba(0, 76, 191, 0.1) 90%, transparent 100%), linear-gradient(71.92deg, rgb(1, 125, 231) 24.51%, rgb(141, 197, 234) 93.46%); */
  background: linear-gradient(90deg, #303A60 0, #2C3661 25%, #394780 65%, #146693 90%, #088CB2 100%);
  height: 100vh;
}

#login_Sec .login_form_wrap {
  border-radius: 15px;
  padding: 3rem;
  width: 25%;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #FFFFFF !important;
}

#login_Sec h1.grommetux-heading.grommetux-heading--strong {
  font-size: 2rem;
  margin-top: 9px;
  font-weight: 700;
  line-height: 2.5rem;
}

#login_Sec .grommetux-form-field .grommetux-form-field__label {
  width: 100% !important;
  float: left;
  margin-right: 5px;
}

/* body{
  overflow: hidden !important;
} */

#login_Sec button.grommetux-button.grommetux-button--primary {
  width: 100%;
}

a#login_forgotPwd {
  text-align: right;
  float: right;
  color: #0073e7;
  font-weight: 400;
  text-transform: none;
  width: 100% !important;
  line-height: 1.125rem;
  text-decoration: underline;
}

section#login_Sec .grommetux-form fieldset {
  margin-bottom: 5px;
  border: none;
  padding: 0px 0px 10px 0px;
}

section.sectionCls:has(section#login_Sec) {
  padding: 0px !important;
}

body:has(section#login_Sec) {
  overflow: hidden;
}

section#login_Sec .logo_wrap>div {
  background: #19224a;
  padding: 2px 20px;
  width: 84px;
}

section#login_Sec .login_otlogo {
  /*position: absolute;
  bottom: 30px;
  left: 28px;*/
  margin: 0px 0px 0px -10px;
}

section#login_Sec .form-title-padding {
  height: auto;
}

section#login_Sec .login_otlogo img {
  width: 125px;
}

/* .grommet.grommetux-app.grommetux-app--inline.bodyfont>div:first-child {
  min-height: 100vh !important;
} */

::-webkit-scrollbar {
  height: 14px;
  width: 14px;
  background: hsla(220, 1%, 40%, .05);
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

::-webkit-scrollbar-thumb {
  background: #656668;
  radius: 1px;
  border: 4px solid transparent;
  background-clip: content-box;
}

.grommet.grommetux-app--inline {
  position: relative;
}

.grommet.grommetux-app {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  overflow: visible;
}

.grommetux-layer.grommetux-layer--hidden {
  left: -100%;
  right: 100%;
  z-index: -1;
  position: fixed;
}

.grommetux-box--direction-column {
  -ms-flex-direction: column;
  flex-direction: column;
}

.grommetux-box--pad-small {
  padding: 12px;
}

.grommetux-box {
  display: -ms-flexbox;
  display: flex;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.grommetux-form:not(.grommetux-form--plain) {
  width: 480px;
}

.grommetux-form {
  margin-top: 0;
  box-shadow: none;
}

.grommetux-form--pad-medium {
  padding: 24px;
}

.grommetux-form {
  position: relative;
  max-width: 100%;
}

.grommetux-layer {
  top: 0;
  bottom: 0;
  background-color: rgba(50, 52, 53, .5);
  height: 100vh;
  overflow: auto;
}

.grommet h1:not(.grommetux-heading),
.grommet h2:not(.grommetux-heading),
.grommet h3:not(.grommetux-heading),
.grommet h4:not(.grommetux-heading),
.grommet h5:not(.grommetux-heading),
.grommet h6:not(.grommetux-heading) {
  font-weight: 400;
  max-width: 100%;
}

.grommet h2 {
  font-style: normal;
  text-transform: uppercase;
  /* letter-spacing: 1.1px; */
}

.grommetux-box--align-start {
  -ms-flex-align: start;
  align-items: flex-start;
}

.grommetux-box--direction-column {
  -ms-flex-direction: column;
  flex-direction: column;
}

.grommetux-box--pad-none {
  padding: 0;
}

.grommetux-box {
  display: -ms-flexbox;
  display: flex;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.grommet h1,
.grommet h1 b,
.grommet h1 strong,
.grommet h2 {
  font-weight: 700;
}

.grommet h1 {
  font-style: normal;
  text-transform: uppercase;
}

h1.grommetux-heading {
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.125;
}

.grommetux-heading--strong {
  font-weight: 700;
}

.grommetux-form-field {
  border: none;
  min-height: 32px;
  overflow: hidden;
}

.grommetux-form-field--text,
.grommetux-form-field--text .grommetux-form-field__label {
  cursor: pointer;
}

.grommetux-form-field {
  width: 100%;
  overflow: auto;
  -webkit-transition: all .4s, padding-top .3s .1s, padding-bottom .3s .1s;
  -o-transition: all .4s, padding-top .3s .1s, padding-bottom .3s .1s;
  transition: all .4s, padding-top .3s .1s, padding-bottom .3s .1s;
}

.grommetux-form-field {
  position: relative;
  padding: 5px 24px;
  border: 1px solid rgba(50, 52, 53, .15);
  margin-bottom: -1px;
  background-color: #fff;
  color: #323435;
  opacity: 1;
}

.grommetux-form-field__label {
  display: block;
  font-size: 14px;
  font-size: .875rem;
  line-height: 24px;
  color: rgba(0, 0, 0, .56);
}

.grommetux-heading {
  font-weight: 400;
  max-width: 100%;
  margin-bottom: 12px;
}

.grommetux-form-field .grommetux-form-field__contents {
  overflow: hidden;
}

.grommetux-form-field__contents {
  display: block;
  margin-left: -24px;
  margin-right: -24px;
}

.grommetux-form-field--text,
.grommetux-form-field--text .grommetux-form-field__label {
  cursor: pointer;
}

.grommetux-form-field {
  position: relative;
  padding: 5px 24px;
  border: 1px solid rgba(50, 52, 53, .15);
  margin-bottom: -1px;
  background-color: #fff;
  color: #323435;
  opacity: 1;
}

/* My CSS */

#login_Sec .grommetux-form-field.grommetux-form-field--text {
  padding: 0px !important;
  margin-bottom: 10px;
  border: none;
}

/* #login_Sec div[class*="FormField__FormFieldBox"] label {
  padding: 0px !important;
  margin: 10px 10px 5px 0px;
  font-size: 0.8rem;
} */

div[class*="FormField__FormFieldContentBox"] {
  padding: 0px !important;
  margin: 0px;
  font-size: 0.8rem;
  border: none;
}

div[class*="FormField__FormFieldContentBox"] input[type="password"],
div[class*="FormField__FormFieldContentBox"] input[type="text"],
div[class*="FormField__FormFieldContentBox"] input[class^="StyledTextInput"]:not(.grommetux-select__drop input, div[class*="FormField__FormFieldContentBox"] button input[class*="StyledTextInput"]) {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  padding: 6px 0px;
  padding-inline: 12px 12px;
  font-size: 0.875rem;
  height: 32px;
  width: 100%;
  box-sizing: border-box;
  font-weight: normal;
}

div[class*="FormField__FormFieldContentBox"] button input[class*="StyledTextInput"] {
  border: none;
  /* border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  font-size: 0.875rem;
  height: 32px;
  width: 100%;
  box-sizing: border-box;
  font-weight: normal; */
}

.grommetux-select__drop input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  font-size: 0.875rem;
  width: 100%;
  box-sizing: border-box;
  font-weight: normal;
  background: #fff;
}

/* div[class*="FormField__FormFieldContentBox"] input:focus {
  outline: none;
  border: 1px solid #0073e7;
} */

#login_Sec span.grommetux-form-field__contents {
  margin: 0px !important;
  width: 100%;
}

/* #login_Sec input {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 8px;
} */

.button_left_wrap button[class^="StyledButton"],
.button_right_wrap button[class^="StyledButton"],
.button_full_wrap button[class^="StyledButton"],
#tipsDiv .ctXbpr {
  background-color: #0073e7;
  border: 0.065rem solid rgba(0, 0, 0, 0);
  border-color: #0073e7;
  border-radius: 0.125rem;
  /* width: 100%; */
  border: none;
  color: #fff;
  height: 2rem;
  font-size: 1rem;
  padding: 0 1rem 0 1rem;
  cursor: pointer;
  min-width: unset;
  font-weight: 400;
  text-transform: none;
  column-gap: 0.375rem;
  align-items: center;
  display: inline-grid;
  text-wrap: nowrap;
}

.button_wrap_primary [class^="StyledButton"] {
  background-color: #0073e7;
  border: 0.065rem solid rgba(0, 0, 0, 0);
  border-color: #0073e7;
  border-radius: 0.125rem;
  /* width: 100%; */
  border: none;
  color: #fff;
  height: 2rem;
  font-size: 1rem;
  padding: 0 1rem 0 1rem;
  cursor: pointer;
  min-width: unset;
  font-weight: 400;
  text-transform: none;
  column-gap: 0.375rem;
  align-items: center;
  display: inline-grid;
}

.button_wrap_primary [class^="StyledButton"]:focus,
.button_wrap_secondary button[class^="StyledButton"]:focus,
.button_wrap_negative button[class^="StyledButton"]:focus {
  outline: none;
  box-shadow: none;
}

.button_wrap_primary [class^="StyledButton"]:active {
  background-color: #0053ac;
}

.button_wrap_primary [class^="StyledButton"]:hover {
  /* background-color: rgba(0, 115, 231,0.5); */
  background-color: #06c;
  box-shadow: none;
  /* color: #fff; */
  /* border-color: rgb(0, 115, 231) !important; */
  /* box-shadow: 0px 0px 0px 2px rgb(0, 115, 231); */
}

.button_wrap_secondary button[class^="StyledButton"] {
  background-color: transparent;
  border: 0.065rem solid rgba(0, 0, 0, 0);
  border-color: #ffffff;
  border-radius: 0.125rem;
  /* width: 100%; */
  border: 0.0625rem solid #4c4e50;
  color: #323435;
  height: 2rem;
  font-size: 1rem;
  padding: 0 1rem 0 1rem;
  cursor: pointer;
  min-width: unset;
  font-weight: 400;
  text-transform: none;
  column-gap: 0.375rem;
  align-items: center;
  display: inline-grid;
}

.button_wrap_secondary button[class^="StyledButton"]:active {
  background-color: #e5e5e5;
}

.button_wrap_secondary button[class^="StyledButton"]:hover {
  /* background-color: rgba(0, 115, 231,0.5); */
  background-color: #f2f2f2;
  box-shadow: none;
  /* color: #fff; */
  /* border-color: rgb(0, 115, 231) !important; */
  /* box-shadow: 0px 0px 0px 2px rgb(0, 115, 231); */
}

.button_wrap_negative button[class^="StyledButton"] {
  background-color: #e5004c;
  border: 0.065rem solid rgba(0, 0, 0, 0);
  border-color: #e5004c;
  border-radius: 0.125rem;
  /* width: 100%; */
  border: none;
  color: #fff;
  height: 2rem;
  font-size: 1rem;
  padding: 0 1rem 0 1rem;
  cursor: pointer;
  min-width: unset;
  font-weight: 400;
  text-transform: none;
  column-gap: 0.375rem;
  align-items: center;
  display: inline-grid;
}

.button_wrap_negative button[class^="StyledButton"]:active {
  background-color: #a30036;
}

.button_wrap_negative button[class^="StyledButton"]:hover {
  /* background-color: rgba(0, 115, 231,0.5); */
  background-color: #c20040;
  box-shadow: none;
  /* color: #fff; */
  /* border-color: rgb(0, 115, 231) !important; */
  /* box-shadow: 0px 0px 0px 2px rgb(0, 115, 231); */
}

section#login_Sec .form-title-padding {
  width: 100%;
  margin-bottom: calc(3rem - 1rem);
}

.button_full_wrap button[class^="StyledButton"] {
  width: 100%;
}

form#reset_formDetail {
  padding: 0px 15px;
}

.panel-footer.panel-button-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px;
}

.panel-footer.panel-button-footer>div {
  margin-left: 8px;
}

label[class^="StyledText-sc"] {
  font-weight: 400;
  font-size: 0.75rem;
  text-transform: none;
  color: #4c4e50;
  margin: 0px 0px 4px 0px;
  line-height: 1.125rem;
}

.grommetux-select__drop label[class^="StyledText-sc"] {
  display: none;
}

.grommetux-select__drop div[class*="FormField__FormFieldBox-sc"] {
  margin-bottom: 0px;
}

div#avatarBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

div#avatarBox .userTabTooltip svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

span.grommetux-button__label {
  display: contents;
}

span#about-icon svg {
  width: 27px !important;
  height: 27px !important;
}

.helpIconCls svg path {
  stroke: #fff !important;
}

button[class^="StyledButton"][role="tab"]:not(#named_user_details_wrap button) {
  background: none;
  display: inline-block;
  padding: 0 0.5rem 0 0.5rem;
  cursor: pointer;
}

button[class^="StyledButton"][role="tab"][aria-selected="true"]::after {
  content: "";
  display: block;
  margin: auto;
  height: 3px;
  background-color: #0073E7;
  line-height: 20px;
}

button[class^="StyledButton"][role="tab"]>div {
  border-bottom: none;
  padding: 0px;
  cursor: none;
}

button[class^="StyledButton"][role="tab"]>div>span {
  color: #000000;
  line-height: 30px;
  text-transform: uppercase !important;
  font-size: 0.82rem;
  cursor: pointer;
}

#header2 {
  background: #f5f7f8;
  border-bottom: 1px solid #ddd;
}

svg#menu-icon-id {
  width: 25px;
}

svg#menu-icon-id path {
  stroke: #fff;
}

/* table tbody tr {
  height: 50px;
} */

table {
  width: 100%;
}

.dragContainer table {
  width: fit-content;
}

/* tbody tr {
  font-size: 15px;
  color: gray;
  line-height: 1.2;
  font-weight: unset;
} */

/* tbody tr:hover {
  color: #555;
  background-color: #f5f5f5;
  cursor: pointer;
} */

table.grommetux-table__table {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  border-collapse: collapse;
}

.tableBody table>tbody>tr>td,
.tableBody table>tbody>tr>th,
.tableBody table>tfoot>tr>td,
.tableBody table>tfoot>tr>th {

  vertical-align: middle;
  border-color: #bdbec0;
  border-style: solid;
  border-width: 0 0 1px 0;
  padding: 5px;
  font-size: 0.82rem;
  font-weight: normal;
  text-align: start !important;
  letter-spacing: normal;
  color: #4c4e50;
  border-collapse: collapse;
  /* position: relative; */
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* .tableBody th *,
td * {
  max-width: 100%;
  overflow: hidden; 
  text-overflow: ellipsis;
  white-space: nowrap;
} */

.tableBody table>tbody>tr:hover {
  background-color: rgba(0, 115, 231, 0.1);
}

th.firstHead,
td.tableRowStyle1 {
  width: 1px;
  padding: 0px !important;
}

.big_menu_wrap .menu_list {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

div#menuTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

title#dcaTitle {
  display: block;
  font-size: 18px;
  /* font-weight: bold; */
  padding-left: 8px;
  padding-right: 32px;
  color: #fff;
}

div#menu-text-id {
  text-transform: capitalize;
  font-size: 18px;
  padding: 0px !important;
  color: #fff;
}

div#menuTitle .logo_wrap:after {
  content: "";
  background: rgb(255, 255, 255);
  position: absolute;
  top: 15%;
  right: 0px;
  height: 18px;
  width: 1px;
}

div#menuTitle .logo_wrap {
  padding-right: 8px;
  position: relative;
  /* border-right: 1px solid #fff; */
}

section.page_section_wrap_1 {
  /* padding: 10px !important; */
  /* display: flex; */
  /* flex-wrap: wrap; */
  clear: both;
}

.config-cont-backgroud {
  max-width: 100vw;
}

td.table-row-header.itsDescending::after {
  content: url("../svg/sort_icon.svg");
  width: 15px;
  height: 15px;
  float: right;
  transform: rotate(180deg);
  margin-top: 5px;
}

td.table-row-header.itsAscending::after {
  content: url("../svg/sort_icon.svg");
  width: 15px;
  height: 15px;
  float: right;
}

.descending {
  content: url("../svg/sort_icon.svg");
  width: 15px;
  height: 15px;
  transform: rotate(180deg);
  margin-top: 5px;
}

.ascending {
  content: url("../svg/sort_icon.svg");
  width: 15px;
  height: 15px;

}



div[class*="StyledCheckBox__StyledCheckBoxBox"] {
  border-radius: 3px;
  width: 20px;
  height: 20px;
  box-shadow: none;
  border: solid 2px rgb(0 0 0 / 73%);
}

.fBcfYK:hover input+div {
  border-color: #5a8aba !important;
}

.fBcfYK input+span {
  border-color: #c5c5c5 !important;
}

input[type="checkbox"]:checked+div[class*="StyledCheckBox__StyledCheckBoxBox"] {
  border: solid 2px #0073e7;
  border-color: #0073e7;
}

div[class*="StyledRadioButton__StyledRadioButtonBox"] {
  border: solid 2px #0073e7;
  height: 18px;
  width: 18px;
}

input[type="checkbox"]:checked+div[class*="StyledCheckBox__StyledCheckBoxBox"] svg {
  stroke: #0073e7;
}

div[class*="StyledRadioButton__StyledRadioButtonBox"] svg {
  fill: #0073e7;
}

a[class^="StyledAnchor"]:not(.big_menu_wrap a, #lic_inst_rightDiv a, .cust_report_rightDiv a, .drag_anchors a, .section_right a, .section_right a, #nameduser_no_lic_msg a, #license_usage_msg a) {
  color: #0073e7;
  font-size: 0.82rem;
  font-weight: normal;
  display: flex;
}

.section_items a,
.section_right a {
  color: #0073e7;
  font-size: 0.82rem;
  font-weight: normal;
}

.action_filter_wrapper {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* margin-bottom: 5px; */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
}

.section_wrap {
  display: flex;
}

.section_wrap .section_items {
  flex: 50%;
  /* padding: 10px; */
}

.wrap_button_left>button {
  /* margin-right: 10px; */
}

.instalLic-btn-wrap {
  display: flex;
}

.wrapper {
  /* position: relative; */
  /* background: #fff; */
  /* margin: 5px; */
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  display: flex;
}

.section_content {
  /* background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5); */
}

.or_separator {
  text-align: center;
  margin: 10px 5px;
  position: relative;
  /* border-style: solid;
  border-width: 2px 0;
  border-color: #878787; */
}

.select_dropdown_icon_wrap>span {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
}

.or_separator::after {
  content: "";
  border-bottom: 1px solid #000;
  display: flex;
  position: absolute;
  top: 50%;
  right: 0px;
  width: 45%;
}

/* .selected_file_div>label {
  font-size: 0.9rem;
} */

.selected_file_div>a {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.config-cont-backgroud>.wrapper {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.or_separator::before {
  content: "";
  border-bottom: 1px solid #000;
  display: flex;
  position: absolute;
  top: 50%;
  width: 45%;
}

#lic_inst_mainSec .section_node_lock_wrap {
  padding: 0px 10px;
}

/* #lic_inst_leftDiv .breadcrumb-div>ul {
  margin: 0px 0px;
} */

.pac_wrapper .breadcrumb-div>ul {
  margin: 0px 0px 15px;
}

.grommetux-section.sectionCls {
  padding: 0px !important;
}

.dbInfotooltip svg {
  fill: #0073e7;
  stroke: #0073e7;
  width: 22px;
}

span.eula_text>label {
  float: left;
  margin: 5px;
}

span.eula_text>p {
  text-align: justify;
  margin: 0px !important;
  line-height: 1rem;
  max-width: none !important;
}

div#select-option-div-id,
div#filter-option-div-id {
  position: absolute;
  z-index: 99;
  background: #fff;
  left: 5px;
}

div#select-option-div-id ol {
  list-style: none;
  padding: 0px;
  margin: 0px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}

div#filter-option-div-id ol {
  list-style: none;
  padding: 0px;
  margin: 0px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}

div#select-option-div-id ol li {
  cursor: pointer;
}

div#filter-option-div-id ol li {
  cursor: pointer;
}

div#select-option-div-id ol li:hover {
  /* box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2); */
  background-color: rgba(0, 115, 231, .02);
  color: #323435;
}

div#filter-option-div-id ol li:hover {
  /* box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2); */
  background-color: rgba(0, 115, 231, .02);
  color: #323435;
}

#filter-option-div-id .grommetux-select__drop {
  border: solid 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
  position: fixed;
  background: #fff;
}

.grommetux-select__option--selected {
  background-color: rgba(0, 115, 231, .08) !important;
  font-weight: 500 !important;
  color: #000 !important;
}

div.dropdown_select {
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  margin: 0 0 0 5px;
  /* height: 24px; */
  border: 1px solid rgba(0, 0, 0, 0.2);
}

a[mimetype="CSV"] .jhNuel *[stroke*='#'],
a[mimetype="CSV"] .jhNuel *[STROKE*='#'],
a.excel .jhNuel *[stroke*='#'],
a.csv .jhNuel *[stroke*='#'] {
  stroke: #0073e7;
  fill: none;
}

.selected_file_div {
  display: flex;
  justify-content: space-between;
  padding: 3px;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* margin-right: 10px; */
  background: rgba(0, 0, 0, 0.05);
}

li.selected_file_list {
  margin-bottom: 2px;
}

#avatarBox div.Activated.grommetux-button__label {
  background: rgba(0, 0, 0, 0.35);
  /* border-radius: 50%; */
}

#avatarBox div.Activated.grommetux-button__label .userTabTooltip svg {
  left: 3px;
  top: 2px;
}

#avatarBox div.Activated.grommetux-button__label #userManagement-icon {
  position: relative;
  left: 2px;
}

div#slide-menu-id>.bigmenu_wrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

div#slide-menu-id>.bigmenu_wrapper .bigmenu_items_wrap {
  padding-left: 3.3rem;
  margin-bottom: 20px;
}

.big_menu_wrap .menu_list li {
  padding: 5px 5px 5px 0px;
}

.button_wrap_primary {
  float: left;
  /* margin-right: 5px; */
}

.page_subsection_wrap {
  padding: 10px;
  display: flex;
  margin-top: 10px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  flex-direction: column;
}

.button_left_wrap {
  display: flex;
  justify-content: flex-start;
  clear: both;
}

.button_right_wrap {
  display: flex;
  justify-content: flex-end;
}

.button_right_wrap button {
  margin-left: 5px;
}

#about_modal_wrap .panel-button-footer button {
  margin-right: 10px;
}

.about_page_logo {
  padding: 0px !important;
}

.action_wrap_left div.dropdown_select {
  margin: 0px !important;
}

.border_white_backg_shadow {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}

.breadcrumb-div {
  flex-basis: 100%;
}

.configUMPassSetting .border_white_backg_shadow {
  display: flex;
}

.feature_excel_wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.with_icon>button {
  display: flex;
  justify-content: space-evenly;
}

.with_icon>button span:first-child {
  display: flex;
}

.with_icon>button span:first-child svg {
  stroke: #fff;
  height: 18px;
}

.button_wrap_primary.with_icon button {
  display: flex;
  flex-direction: row;
}

.primary_text_color svg {
  stroke: #0073E7 !important;
}

div[tooltiptext="Import History"] {
  padding-top: 5px;
  height: 1.5rem;
}

label#import-pool-option-id {
  cursor: pointer;
}

div#createPoolPopupId {
  z-index: 99;
}

.action_wrap_left {
  display: flex;
  width: 100%;
}

.inline-flex-wrap.password_reset_day>div {
  display: flex;
}

div#lic_inst_mainSec {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}

.OtLoadingSpinner {
  background-size: 100%;
}

div#spinnerIcon {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  margin: 0px !important;
  align-items: center;
}

.reservationMemTitle_wrap {
  display: flex;
  justify-content: flex-start;
}

div#reservation_pool_name_id {
  display: flex;
  width: 50%;
}

.attribute_action_wrap {
  display: flex;
  padding: 5px 0;
}

.attribute_action_wrap {
  display: flex;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.attribute_action_wrap>div {
  padding-right: 10px;
}

.select_attribute {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 5px;
  margin: 0px;
  height: 30px;
  min-width: 9rem;
}

input#pool-attributes-search-id {
  height: 32px;
}

input:focus {
  outline: none;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.06) !important;
}

p.treegrid-p {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex_start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.mar_left_10 {
  margin-left: 10px;
}

.mar_right_10 {
  margin-right: 10px;
}

.panel-footer.panel-button-footer>div {
  margin: 0px 10px;
}

.productBaseFeatures {
  border: 1px solid rgba(0, 0, 0, 0.07);
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
  margin-top: 10px;
}

.file_upload_wrap {
  display: flex;
  /* min-width: 50rem; */
  flex-direction: column;
}

div#attach_file_table_id {
  width: 100%;
}

.commuter_file_upload_wrap {
  display: flex;
  flex-direction: column;
}


span.fileFieldLabel>label {
  margin-top: 5px;
}

a>* {
  cursor: pointer;
}

.usage_search_wrap svg {
  margin-left: 10px;
  margin-top: 2px;
}

svg[aria-label="FormDown"] {
  fill: #0073e7;
  stroke: #0073e7;
}

.flex_fw_fdc {
  display: flex;
  flex-direction: column;
}

.flex_jc_fs {
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
}

button.tokenPopUp {
  margin-left: 7px;
}

a#user_resetPwd {
  margin-top: 6px;
}

#about_modal_wrap h2 {
  margin: 0px !important;
  padding: 5px;
  border: 1px solid #19224b;
}

#about_modal_wrap .about-div-cls {
  display: flex;
  flex-direction: column;
}

#about_modal_wrap .about-div-cls>div {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

#about_modal_wrap .about-div-cls>div:nth-child(1) {
  display: flex;
  justify-content: space-between;
}

#about_modal_wrap .about-div-cls>div:nth-child(2) {
  display: flex;
}

#about_modal_wrap .about-div-cls>div:nth-child(2) img {
  min-width: 40rem;
  width: 100%;
  height: auto;
  max-width: 100%;
}

#about_modal_wrap .about-div-cls>div:nth-child(4)>div {
  display: flex;
}

#about_modal_wrap .about-div-cls>div:nth-child(4)>div>div {
  width: 50%;
  float: left;
}

.aboutPage_title5 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px 0px;
}

.abouttable.flex_spcbtn {
  flex-direction: row !important;
}

#about_modal_wrap .about-div-cls>div>div {
  padding: 5px 0px;
}

.about_page_logo>img {
  width: 8rem;
}

div#aboutPagetitle1 {
  font-weight: bold;
  font-size: 1rem;
}

#about_modal_wrap .about-div-cls>div:nth-child(4)>div>div:first-child {
  font-weight: bold;
}

.aptable-pagination.border_white_backg_shadow {
  margin-top: 0px;
  box-sizing: border-box;
  padding: 0px;
}

.aptable-pagination.border_white_backg_shadow .pagination_wrapper {
  padding: 5px;
}

.two_col_wrap {
  display: flex;
  flex-flow: wrap;
}

/* #items_found_count_id {
  padding: 5px;
  font-size: 0.9rem;
} */

/* span#select-component-label-id {
  font-size: 0.9rem;
} */

/* span#select-component-value-id {
  font-size: 0.9rem;
} */

.select_license_meg {
  /* font-size: 0.9rem; */
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}

.password_reset_day div {
  margin: 0px 7px 0 0 !important;
  align-items: center;
}

.button_full_wrap button[class^="StyledButton"]:hover {
  box-shadow: none !important;
  /*0px 0px 0px 2px #158aff !important;*/
}

.avatar_div>div {
  display: flex;
  justify-content: center;
  flex-direction: row;

}

.avatar_div1 {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.avatar_div>div {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  padding: 10px;
}

.avatar_div>div>div:first-child {
  padding-right: 7px;
  font-weight: bold;
}

.avatar_div>div:first-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.create_user_popup_wrap.button_right_wrap {
  margin-right: 10px;
}

.critical.notification-message>#message-id {
  background: #e5004c;
}

.normal.notification-message>#message-id {
  background-color: rgb(26, 172, 96);
}


.notification-message>#message-id p[class^="StyledParagraph"] {
  margin: 0px !important;
}

.notification-message>#message-id p[class^="StyledParagraph"] span {
  font-size: 0.8rem;
}

.critical.notification-message>#message-id p[class^="StyledParagraph"] span {
  color: #ffffff;
}

.normal.notification-message>#message-id p[class^="StyledParagraph"] span {
  color: #ffffff;
}

.critical.notification-message>#message-id>div>div+div {
  width: 100%;
}

.normal.notification-message>#message-id>div>div+div {
  width: 100%;
}

.notification-message>#message-id button[class^="StyledButton"] svg {
  fill: #ffffff !important;
  stroke: #ffffff !important;
}

#avatarBox div.bigmenu_items_wrap>div:hover {
  background: rgba(0, 0, 0, 0.25);
}

.logo_wrap img {
  height: 27px;
}

.userDetails_status_label {
  margin-bottom: 10px;
}

div#umUserNameDiv {
  clear: both;
  display: flex;
}

.user_name_wrap {
  padding-left: 6px;
}

.userDetails_status_label>label {
  font-weight: bold;
}

.grommetux-password-input.password_icon {
  position: relative;
  width: 100%;
}

.test_mail_wrap {
  display: flex;
  align-items: center;
}

/* 
.test_mail_wrap button#conf_testMailButton {
  margin-top: 10px;
} */

.overrideTableBottom {
  display: flex;
  align-items: center;
  margin: 0px 0px 10px 15px !important;
}

.overrideTableBottom>div {
  margin-right: 10px;
}

.overrideTableBottom>div div {
  margin: 0 !important;
}

.overrideTableBottom>div span {
  padding-left: 10px;
}

label[class="StyledCheckBox__StyledCheckBoxContainer"]:hover input:not([disabled])+div,
label[class="StyledCheckBox__StyledCheckBoxContainer"]:hover input:not([disabled])+span {
  border-color: #cccccc !important;
}

.excludeClientRightDesc.button_left_wrap {
  margin: 10px 0px 0px 5px;
}

.audit_conf_wrap {
  display: flex;
}

.protoTypeRight div {
  margin-bottom: 0px !important;
}

.config_prototype_wrap {
  margin-bottom: 10px;
}

.autoPurgeConfigLeftDesc.prodAuthentication {
  float: left;
}

button[class*="StyledSelect__SelectOption"] * {
  font-size: 0.8rem !important;
}

button[class*="StyledSelect__SelectOption"]>div {
  padding: 5px;
  margin-left: 10px;
}

button[class*="StyledSelect__SelectOption"]:hover {
  background-color: rgba(0, 115, 231, .05) !important;
}

button[class*="StyledSelect__SelectOption"] {
  background-color: transparent !important;
  color: #000000;
}

.aptable-pagination .pagination_wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.aptable-pagination .pagination_left {
  display: flex;
  justify-content: flex-start;
  align-content: center;
}

th.secondnthirdTh {
  width: 2rem;
}

.horizantal_label_input>div:not(.dbInfotooltip) {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  /* display: block; */
  /* padding: 10px; */
  flex-wrap: wrap;
  clear: both;
}

.horizantal_label_input label[class^="StyledText"]+div {
  display: flex;
  flex-grow: 1;
}

.horizantal_label_input {
  position: relative;
}

.horizantal_label_input>div>label {
  align-items: center;
  display: flex;
  padding: 5px;
  flex: 0 0 34%;
}

.horizantal_label_input .db_url_tooltip {
  position: absolute;
  left: 6rem;
  top: -4px;
}

#conf_db_formId_wrap {
  display: flex;
}

div[class*="FormField__FormFieldContentBox"] select {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  padding: 6px 0px;
  padding-inline: 12px 12px;
  font-size: 0.875rem;
  height: 32px;
  width: 100%;
  box-sizing: border-box;
  font-weight: normal;
}

div[class*="FormField__FormFieldContentBox"] select:focus-visible,
div[class*="FormField__FormFieldContentBox"] select:focus {
  outline: none;
  border: 1px solid #0073e7;
}

.imp_note {
  margin-bottom: 15px;
}

.notification_wrapper {
  clear: both;
}

input[class^="StyledCheckBox__StyledCheckBoxInput"]+span>span {
  top: -5px;
  left: -4px;
  width: 16px;
  height: 16px;
  border: 1px solid #1d1d1d;
  background: #fff;
}

input[class^=StyledCheckBox__StyledCheckBoxInput]:checked+span>span {
  border: 1px solid #0073e7 !important;
}

input[class^="StyledCheckBox__StyledCheckBoxInput"]:checked+span>span {
  left: calc(25px - 16px) !important;
  background: #0073e7 !important;
}

.saml_conf_wrap .db_config_wrap_item {
  width: 60rem;
}

.horizantal_label_input span.fileFieldLabel label {
  padding: 8px 5px;
  font-size: 11px;
  margin-top: 2px;
}

div[class^="StyledBox-sc-"]:has(svg[aria-label="Calendar"]) {
  /* height: 1.9rem; */
}

.notification-message svg:not(svg[aria-label="FormClose"]) {
  display: none;
}

.notification-message-comp svg:not(svg[aria-label="FormClose"]) {
  display: none;
}

.action_wrap_left .button_wrap_primary,
.action_wrap_left .button_wrap_negative,
.button_left_wrap .button_wrap_primary,
.button_left_wrap .button_wrap_secondary,
.button_left_wrap .button_wrap_negative,
.button_left_wrap input {
  margin-right: 5px;
}

span#group-associate-id {
  display: flex;
  justify-content: flex-start;
}

a#user_search {
  margin-left: -28px;
  margin-right: 13px;
  position: relative;
  top: 2px;
}

.spinner_wrap {
  display: flex;
  box-sizing: border-box;
  position: relative;
}

section#lic_inst_WizardSec {
  padding: 10px;
}

span#group-associate-id>div:first-child {
  display: contents;
}

/* #lic_tenant_listSec */
.action_wrap_left>div {
  margin-right: 5px;
}

.action_wrap_left .button_wrap_primary+div>.select-drop-down div.dropdown_select {
  margin-left: 0px;
}

label[for="conf_saml_certificate_browse"] {
  padding: 5px;
}

input[class^="StyledCheckBox__StyledCheckBoxInput"]+span {
  /* display: block; */
  width: 24px;
  height: 8px;
  background: rgba(0, 0, 0, 0.2);
  border: 2px solid rgb(204 204 204) !important;
  box-shadow: none;
}

input[class^="StyledCheckBox__StyledCheckBoxInput"]:hover+span {
  /* display: block; */
  border-color: #cccccc !important;
  background: rgba(0, 0, 0, 0.2);
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.user_details_group {
  box-sizing: border-box;
}

button[class*="StyledSelect__StyledSelectDropButton"] {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

div[class*="FormField__FormFieldContentBox"]:has(input.datepicker)>div {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.datepicker input {
  padding: 8px 5px 8px 10px;
}

/* .button_wrap_primary span span {
  font-size: 0.9rem;
} */

svg[class^="StyledIcon-sc"] {
  fill: #0073e7;
  stroke: #0073e7;
  width: 20px;
}

div[class^="StyledMaskedInput__StyledMaskedInputContainer"]+button {
  margin: 0px 5px;
}

div#lic_cle_wizSec {
  width: 100%;
}

.pool_list_tbl_wrap {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px 10px;
  margin-top: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* margin-bottom: 5px; */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
}

.saveBtnStyle.button_left_wrap {
  width: 100%;
}

.grommetux-form-field--error+p,
span.grommetux-form-field__error {
  color: #e5004c !important;
  margin: 0px !important;
}

.popup_tenant_wrap {
  padding: 10px;
}

.menu-icon-cls {
  cursor: pointer;
}

div.back-button-container svg {
  width: 35px;
}

.reservationMemTitle {
  margin-top: 10px;
}

.pad_all_10 {
  padding: 10px;
  box-sizing: border-box;
}

.big_menu_wrap .menu_list li a {
  font-weight: normal;
}

.copy_token_icon_wrap {
  position: relative;
  height: 30px;
  align-content: center;
  display: flex;
  flex-wrap: wrap;
}

span.grommetux-button.grommetux-button--primary {
  background: #0073e7;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}

span.grommetux-button.grommetux-button--secondary {
  background: #e5004c;
  color: white;
  margin-left: 5px !important;
  border-radius: 3px;
  cursor: pointer;
}

/* thead {
  position: sticky;
  top: -1px;
  z-index: 15;
}

thead tr td,
thead tr th {
  background-color: #fff;
  position: sticky;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

table th:first-child,
table td:first-child {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

table th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
} */


/* button:hover {
  background-color: #000000;
  width: 1rem;
  height: 1rem;
  position: absolute;
  border-radius: 50%;
  transform: translateX(-100%) translateY(-100%);
  mix-blend-mode: screen;
  animation: ripple 1250ms ease-out forwards, fade 1500ms ease-out forwards;
}

@keyframes ripple {
  0% {
    transform: translate(-100%, -100%);
  }

  80% {
    transform: translate(-100%, -100%) scale(50);
  }

  100% {
    transform: translate(-100%, -100%) scale(50);
    opacity: 0;
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
} */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #login_Sec .login_form_wrap {
    width: 80%;
  }

  .left_grid::after {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #login_Sec .login_form_wrap {
    width: 65%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #login_Sec .login_form_wrap {
    width: 45%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #login_Sec .login_form_wrap {
    width: 35%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #login_Sec .login_form_wrap {
    width: 20.5rem;
  }
}

.select_component_wrap {
  font-size: 0.82rem;
  font-weight: normal;
}

.bodyfont {
  font-size: 0.82rem !important;
}

::-webkit-input-placeholder {
  /* Edge */
  font-weight: normal !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-weight: normal !important;
}

::placeholder {
  font-weight: normal !important;
}

.custom_report_table table>tbody>tr>td {
  word-wrap: break-word;
  padding: 5px !important;
}

.filter-values table>tbody>tr>td {
  word-wrap: break-word;
  padding: 0px !important;
}

.grommetux-tile {
  -webkit-box-shadow: 2px 2px rgba(0, 0, 0, .15);
  box-shadow: 2px 2px rgba(0, 0, 0, .15);
  border-top: 1px solid rgba(50, 52, 53, .15);
}

.grommetux-tile {
  overflow: hidden;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}

.saml_check_radio>div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.saml_check_radio>div>label {
  margin-right: 7em;
}

.saml_check_radio {
  width: 25em;
  clear: both;
}

.saml_check_radio div[class*="FormField__FormFieldBox-sc"] label {
  /* width: 30%;
  padding: 10px; */
}

.saml_check_radio div[class*="FormField__FormFieldContentBox-sc"] label {
  /* margin-top: -45px;
  margin-left: 30%; */
}

div[class*="StyledBox"][class*="StyledDrop"] button>div[class^="StyledCalendar__StyledDay"] {
  width: 30px;
  height: 30px;
}

/* div[class*="StyledBox"][class*="StyledDrop"] {
  width: 19.5rem !important;
} */

div[class*="StyledBox"][class*="StyledDrop"]>div {
  max-width: 100% !important;
}

div[class*="StyledBox"][class*="StyledDrop"] div[role="grid"] {
  height: fit-content !important;
  font-size: 0.8rem;
  box-shadow: none !important;
}

div[class*="StyledBox"][class*="StyledDrop"] div[role="grid"] .fhqWDe {
  background-color: #0073e7;
}

div[class*="StyledBox"][class*="StyledDrop"]>div>div>div:first-child input[type="search"] {
  font-size: 0.8rem !important;
}

div[class*="StyledBox"][class*="StyledDrop"]>div:first-child [role="alert"] {
  font-size: 0.8rem !important;
}

div[id$="select-drop"]>div>div:first-child::before {
  position: absolute;
  top: 22%;
  content: url("../svg/search.svg");
  width: 20px;
  height: 20px;
  float: left;
  fill: #0073e7;
  stroke: #0073e7;
  left: 12px;
  /* transform: rotate(180deg);
  margin-top: 5px; */
}

div[class*="StyledBox"][class*="StyledDrop"]>div>div>div:first-child:not(div[class*="StyledTextInput__"]) {
  padding: 5px;
  box-shadow: inset 0px 0px 2px 0px rgba(0, 0, 0, .15);
}

div[class*="StyledBox"][class*="StyledDrop"]>div>div>div:first-child button[type="button"] {
  padding: 0 0 0 5px;
}

div[class*="StyledBox"][class*="StyledDrop"]>div>div>div:first-child * {
  font-size: 1.2rem !important;
}

div[class*="StyledBox"][class*="StyledDrop"]>div>div>div:first-child header {
  padding-left: 5px;
}

.action_flex_wrap {
  max-width: 25%;
  /* flex-wrap: wrap; */
  margin-right: 10px;
  display: grid;
  flex-grow: 1;
}

.action_flex_wrap.app_switch {
  max-width: 150px;
}

.action_flex_wrap label[class^="StyledText-sc"] {
  float: left;
}

.action_flex_wrap.horizantal_form_item>div {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0px;
}

.action_flex_wrap.horizantal_form_item>div>label {
  margin-right: 5px;
}

.action_flex_wrap.horizantal_form_item>div>div {
  flex-grow: 1;
}

.App_Wrap>div:first-child {
  height: 55px;
}

.floatRight {
  display: flex;
}

.floatRight>a {
  margin-left: 5px;
}

.App_Wrap {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.buymore_wrapper .action_filter_wrapper {
  margin-bottom: 10px;
}

h2.headerConfig {
  margin-bottom: 7px !important;
  font-size: 1rem !important;
  font-weight: bold !important;
  letter-spacing: 0px !important;
  display: flex;
  clear: both;
}

.updateUserDiv:first-child input {
  width: 96% !important;
  border-radius: 3px;
  height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.flex_parent_2,
.flex_parent_3 {
  display: flex;
}

.flex_parent_2>*,
.flex_parent_3>* {
  flex-grow: 1;
}

.flex_parent_2>div>div,
.flex_parent_3>div>div {
  display: flex;
  /* flex-grow: 1;
  flex-shrink: 1;
  align-items: center; */
}

.flex_parent_2>div:first-child {
  margin-right: 5px;
}

.flex_parent_2>*>label+div {
  /* margin-right: 5px; */
}

.flex_parent_2>*>label {
  flex-grow: 1;
  width: 100%;
  display: flex;
}

.license_report_content_wrap {
  position: relative;
}

.flex_parent_2>*>label+button {
  width: 100%;
}

.license-report-search-popup.display-block {
  display: block;
  animation: slide-right 0.5s 1;
  animation-fill-mode: forwards;
  padding: 10px;
  border-radius: 5px;
}

@keyframes slide-right {
  0% {
    transform: translateX(300px);
  }

  100% {
    transform: translateX(30px);
  }
}

.section_right {
  display: flex;
  justify-content: flex-end;
}

#text-input-drop__create_pool_parent_name_id {
  z-index: 99;
}

.float_left_mar_5 {
  float: left;
  margin-right: 5px;
}

.align_center {
  align-items: center;
  display: flex;
}

#nameduser_grid_id div[id^="div_"] {
  display: flex !important;
  justify-content: flex-start;
}

#nameduser_grid_id div[id^="div_"]>a {
  padding-left: 10px;
}

#nameduser_grid_id div[id^="div_"] svg {
  stroke: #0073E7;
  width: 18px;
}

.tbl_inside_row_input {
  height: 2rem;
}

.product_report_left {
  float: left;
  width: 23%;
  padding-top: 10px;
}

/* div#productReportSearchDiv>div>form>div>div {
  right: 0px;
  position: absolute;
  top: 16px;
} */

.product_report_right {
  float: left;
  width: 77%;
  padding-left: 10px;
  box-sizing: border-box;
}

.token_modal_tableBody td {
  border: 1px solid #6d6d6d;
  padding: 5px;
}

.token_modal_tableBody table {
  border-collapse: collapse;
}

.mar_top_10 {
  margin-top: 10px;
}

.token_data_table {
  display: flex;
  flex-direction: column;
}

div[class^="aggreement_"]>label {
  float: left;
  margin-right: 10px;
}

div[class^="aggreement_"]>span {
  margin-right: 5px;
}

.cust_report_table td {
  vertical-align: middle;
  padding: 10px;
  text-align: start !important;
  letter-spacing: normal;
  border-collapse: collapse;
  position: relative;
  max-width: 100%;
}

.wip header[class^="StyledBox"] {
  padding: 10px 0px;
}

button[id="usage_legendsId"] {
  display: none;
}

.inuse-cont-backgroud div[class*="SelectMultiple__StyledSelectBox-sc"] {
  border: 0 !important;
  border-radius: 0px !important;
}

.inuse-cont-backgroud div[class*="SelectMultiple__StyledSelectBox-sc"] button {
  border: 0 !important;
  border-radius: 0px !important;
}

.inuse-cont-backgroud div[class*="SelectMultiple__StyledSelectBox-sc"] button:hover {
  background-color: transparent !important;
}

.grommetux-select__options {
  /* position: fixed; */
  z-index: 20;
  border-radius: 0;
  overflow: auto;
  background-color: #fff;
  list-style-type: none;
}

input[type="radio"]+div svg {
  width: 20px !important;
  height: 20px !important;
}

div[class*="StyledRadioButton__StyledRadioButtonBox"]:hover {
  border-color: #0073E7 !important;
}

.grommetux-legend {
  text-align: left;
  white-space: normal;
  display: inline-block;
  line-height: 24px;
}

.grommetux-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.grommetux-legend__item-label {
  margin-right: 12px;
}

.grommetux-legend__item-swatch {
  width: 12px;
  height: 12px;
  margin-right: 12px;
  overflow: visible;
}

.grommetux-color-index-highlight-2 {
  background: #00abf3;
}

.grommetux-color-index-statushighlight-3 {
  background: #fde159;
}

.horizantal_label_input label[class^="StyledText"]+div+span {
  color: #FF4040;
  font-size: 0.75rem;
  flex-basis: 100%;
  text-align: left;
  margin-left: 36%;
}

div[class*="FormField__FormFieldBox-sc"]>label+div+span {
  color: #FF4040;
  font-size: 0.75rem;
  flex-basis: 100%;
  text-align: right;
  margin: 0px;
}

div[class*="FormField__FormFieldBox-sc"]>label+div+span:before {
  content: '';
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxjaXJjbGUgc3R5bGU9ImZpbGw6I0U1MDA0QzsiIGN4PSI4IiBjeT0iOCIgcj0iOCIvPg0KPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik05LjQsOGwyLjMtMi4zYzAuNC0wLjQsMC40LTEsMC0xLjRzLTEtMC40LTEuNCwwTDgsNi42TDUuNyw0LjNjLTAuNC0wLjQtMS0wLjQtMS40LDBzLTAuNCwxLDAsMS40DQoJTDYuNiw4bC0yLjMsMi4zYy0wLjQsMC40LTAuNCwxLDAsMS40QzQuNSwxMS45LDQuNywxMiw1LDEyczAuNS0wLjEsMC43LTAuM0w4LDkuNGwyLjMsMi4zYzAuMiwwLjIsMC41LDAuMywwLjcsMC4zczAuNS0wLjEsMC43LTAuMw0KCWMwLjQtMC40LDAuNC0xLDAtMS40TDkuNCw4eiIvPg0KPC9zdmc+DQo=);
  display: inline-block;
  height: 14px;
  width: 14px;
  margin-right: 5px;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.horizantal_label_input div[class^="StyledBox"]>div+span {
  color: #FF4040;
  font-size: 0.75rem;
  flex-basis: 100%;
  text-align: left;
  /* margin-left: 36%; */
}

div[class*="FormField__FormFieldBox-sc"]>div+span:before {
  content: '';
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxjaXJjbGUgc3R5bGU9ImZpbGw6I0U1MDA0QzsiIGN4PSI4IiBjeT0iOCIgcj0iOCIvPg0KPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik05LjQsOGwyLjMtMi4zYzAuNC0wLjQsMC40LTEsMC0xLjRzLTEtMC40LTEuNCwwTDgsNi42TDUuNyw0LjNjLTAuNC0wLjQtMS0wLjQtMS40LDBzLTAuNCwxLDAsMS40DQoJTDYuNiw4bC0yLjMsMi4zYy0wLjQsMC40LTAuNCwxLDAsMS40QzQuNSwxMS45LDQuNywxMiw1LDEyczAuNS0wLjEsMC43LTAuM0w4LDkuNGwyLjMsMi4zYzAuMiwwLjIsMC41LDAuMywwLjcsMC4zczAuNS0wLjEsMC43LTAuMw0KCWMwLjQtMC40LDAuNC0xLDAtMS40TDkuNCw4eiIvPg0KPC9zdmc+DQo=);
  display: inline-block;
  height: 14px;
  width: 14px;
  font-size: 0.75rem;
  margin-right: 5px;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.grommetux-color-index-statushighlight-2 {
  background: #f48b34;
}

div#lic_clen_tokenGrid>table {
  width: 100%;
}

form#lic_clen_upload {
  width: 45rem;
}

span[class*="FormField__RequiredText"] {
  color: #e5004c;
  font-size: 15px;
  margin-left: 5px;
}

.wrapper>div {
  width: 95%;
}

.system_cinfig_inp_wrap {
  width: 30rem;
  clear: both;
}

.horizantal_label_input.system_cinfig_inp_wrap {
  /* flex-direction: row;
  display: flex; */
}

.test_mail_wrap>div {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  float: left;
  margin-bottom: 15px;
  align-items: center;
}

.test_mail_wrap>div label[class^="StyledText-sc"] {
  width: 10.6rem;
  padding-left: 5px;
}

.test_mail_wrap>div label[class^="StyledText-sc"]+div {
  width: 19rem;
}

.test_mail_wrap>div label[class^="StyledText-sc"]+div input {
  width: 100%;
}

.select_purge_type_wrap,
.ipaddress_input_wrap {
  clear: both;
}

.ipaddress_input_wrap div[class*="FormField__FormFieldContentBox"] {
  width: 21rem;
}

.ipAddress_tbl_wrap {
  padding-right: 10px;
}

.ldapConfig_wrap {
  width: 35rem;
}

div[class^="StyledTextInput__StyledTextInputContainer"] input.configEntryEdit {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 2px !important;
  padding: 6px 0px;
  padding-inline: 12px 12px;
  font-size: 0.875rem;
  height: 32px;
  width: 20%;
  box-sizing: border-box;
  font-weight: normal;
}

.min_inp_width_20rem input {
  width: 20rem !important;
}

.horizantal_label_input.ct_input_wrap {
  width: 50rem;
}

h1.grommetux-heading.login_autopass_title {
  margin-bottom: 0px !important;
}

h1.grommetux-heading.login_license_server {
  margin-top: 0px !important;
}

.border_white_backg {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  padding: 10px;
  flex-wrap: wrap;
}

.buymore_header {
  width: 100%;
  background: #fff;
  margin: 0px !important;
  padding: 10px !important;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
}

input[class^="StyledMaskedInput-sc"] {
  padding: 0.4rem 5px;
}

.autonameduser_radio {
  width: 25em;
  clear: both;
}

.autonameduser_radio>div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 0px;
}

.autonameduser_radio>div>label {
  font-size: 0.82rem !important;
  margin-right: 2em;
  margin-bottom: 0px;
  padding: 3px;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  white-space: pre-line;
  position: relative;
  font-size: 0.82rem !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  white-space: pre-line;
  position: relative;
  font-size: 0.82rem !important;
}

:-ms-input-placeholder {
  /* IE 10+ */
  white-space: pre-line;
  position: relative;
  font-size: 0.82rem !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  white-space: pre-line;
  position: relative;
  font-size: 0.82rem !important;
}

input::placeholder {
  white-space: pre-line;
  position: relative;
  font-size: 0.82rem !important;
}

input[type="search"] {
  padding: 6px !important;
  padding-left: 48px !important;
}

.expandPlaceHolder input::placeholder {
  top: -9px;
  white-space: pre-line;
  position: relative;
  font-size: 0.82rem !important;
}

input[data-title] {
  opacity: 100 !important;
}

input[data-title]:hover::before {
  /* display: none; */
  content: attr(data-title);
  position: absolute;
  padding: 4px;
  background-color: #FFFFFF;
  border: 1px solid #cccccc;
  margin-top: 20px;
  z-index: 1;
  margin-left: 10px;
  opacity: 100 !important;
  width: auto;
  min-width: 250px;
  max-width: 250px;
  word-wrap: break-word;
  white-space: pre-line;
}

.button_wrap_primary_wofloat [class^="StyledButton"] {
  background-color: #0073e7;
  border: 0.065rem solid rgba(0, 0, 0, 0);
  border-color: #0073e7;
  border-radius: 0.125rem;
  border: none;
  color: #fff;
  height: 2rem;
  font-size: 1rem;
  padding: 0 1rem 0 1rem;
  cursor: pointer;
  min-width: unset;
  font-weight: 400;
  text-transform: none;
  column-gap: 0.375rem;
  align-items: center;
  display: inline-grid;
}

.button_wrap_primary_wofloat [class^="StyledButton"]:focus,
.button_wrap_secondary_wofloat button[class^="StyledButton"]:focus,
.button_wrap_negative_wofloat button[class^="StyledButton"]:focus {
  outline: none;
  box-shadow: none;
}

.button_wrap_primary_wofloat [class^="StyledButton"]:active {
  background-color: #0053ac;
}

.button_wrap_primary_wofloat [class^="StyledButton"]:hover {
  background-color: #06c;
  box-shadow: none;
}