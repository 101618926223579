.App {
    text-align: center;
}

html,
body {
    min-height: 100vh;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.leftgrid {
    width: 46%;
    padding: 2px !important;
}

.rightgrid {
    width: 49%;
    border-left: 1px solid #CCC;
}

.leftgrid,
.rightgrid {
    float: left;
    border-bottom: 1px solid #CCC;
}

.headerConfig {
    color: #5a5a5a;
    /* font-weight: 500 !important; */
    font-size: 17px !important;
    /* margin-left: 5px !important; */
    padding-bottom: 0px !important;
    /* padding-left: 0px !important; */
    text-transform: unset !important;
    float: left;
    margin-top: 0px !important;
    width: 100%;
    margin-bottom: 10px !important;
}

.purge {
    font-size: 16px;
    padding-left: 10px;
}

.mailServerConfigLeftDesc {
    width: 30% !important;
    float: left !important;
    padding: 5px;
    line-height: 2.0;
    color: #5a5a5a;
    padding-left: 30px;
}

.mailServerConfigRightDesc {
    border: 0px !important;
    width: 65%;
    padding: 5px !important;
    float: right;
}

.separator {
    background-color: #D2D2D2;
    border: 0;
    height: 1px;
    margin: 5px 0 5px 0px;
    width: 100%;
    float: left;
}

.autoPurgeConfigLeftDesc {
    /* width: 45%;
    float: left !important;
    padding: 0px;
    line-height: 2.0;
    color: #5a5a5a;
    padding-left: 30px; */
    /* flex: 0 0 50%; */
    margin-right: 15px;
}

.autoPurgeConfigRightDesc {
    /* border: 0px !important;
    width: 53%;
    padding: 1px !important;
    float: right; */
    flex: 0 0 50%;
}

.usageHubConnetor {
    padding-bottom: 5px;
}

.inputComp {
    border-bottom: 1px solid #CCC !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    width: 100%;
    padding: 0px !important;
    margin-left: 2px;
}

.prodAuthentication {
    padding-bottom: 5px;
    width: 20% !important;
}

.protoTypeCls {
    /* padding-bottom: 5px;
    width: 50% !important; */
}

.protoTypeRight {
    /* width: 53% !important; */
}

.protoTypeLabel {
    padding-left: 0px !important;
}

.enablePurgeType {
    display: inline;
}

.disanblePurgeType {
    display: none;
}

.enableAdvanceSettings {
    display: inline;
}

.disanbleAdvanceSettings {
    display: none;
}

.excludeClientLeftDesc {
    /* width: 65%; */
    float: left !important;
    padding: 0px;
    line-height: 2.0;
    color: #5a5a5a;
}

.excludeClientRightDesc {
    border: 0px !important;
    width: 25%;
    padding: 1px !important;
    float: right;
}

.enableExcludeClientsSection {
    display: inline;
}

.disanbleExcludeClientsSection {
    display: none;
}

.configUMPassSetting {
    width: 60%;
    padding: 2px !important;
}

.saveBtnStyle {
    /* width: 100%;
    float: left;
    padding-top: 5px;
    padding-left: 25px;
    margin-bottom: 10px; */
}

.headerText {
    line-height: 33px;
    color: #5a5a5a;
    height: 35px;
    font-size: 18px;
    text-align: center;
    border-bottom: 1px solid #d3d3d3;
}

.disabledTextField {
    background-color: rgb(240, 235, 235) !important;
    pointer-events: none;
}

.enabledTextField {
    background-color: #FFFFFF;
}

.tooltipMain {
    /*position: relative;*/
    display: inline-block;
    position: initial;
    white-space: pre;
    width: 95%;
}

.tooltipContent {
    visibility: hidden;
    width: 30%;
    background-color: #555;
    color: #fff;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    left: 40%;
    margin-left: -50px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltipContentHide {
    content: "";
    position: absolute;
    top: 90%;
    left: 45%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltipContentshow {
    visibility: visible;
    opacity: 1;
}

.grommetux-box--pad-horizontal-small {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.tick text {
    -ms-transform: rotate(-60deg) !important;
    /* IE 9 */
    -webkit-transform: rotate(-60deg) !important;
    /* Safari 3-8 */
    transform: rotate(-50deg);
    font-size: 12px !important;
    text-anchor: end;
}

.rejectedCls:hover {
    text-decoration: underline;
}

.tick {
    padding-top: 40px !important;
}

/**
Override for sync the Radio and Check label text with FormField label text
*/
.grommetux-check-box__label {
    display: inline-block !important;
    font-size: 14px !important;
    font-size: 0.875rem !important;
    line-height: 24px !important;
    color: rgba(0, 0, 0, 0.56) !important;
}

.grommetux-radio-button__label {
    display: inline-block !important;
    font-size: 14px !important;
    font-size: 0.875rem !important;
    line-height: 24px !important;
    color: rgba(0, 0, 0, 0.56) !important;
}

.usagePopupDiv {
    position: fixed;
    top: 40%;
    left: 35%;
    width: 35%;
    height: 35%;
    background: #fff;
    border: 2px solid #ccc;
    padding: 20PX;
    border-radius: 10px;
    pointer-events: auto;
    z-index: 99999;
}

.usagePopupDiv:target {
    opacity: 1;
    pointer-events: auto;
}

.usageClose {
    background: #606061;
    color: #FFFFFF;
    line-height: 25px;
    position: absolute;
    right: -12px;
    text-align: center;
    top: -10px;
    width: 24px;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    -moz-box-shadow: 1px 1px 3px #000;
    -webkit-box-shadow: 1px 1px 3px #000;
    box-shadow: 1px 1px 3px #000;
}

.dbInfotooltipText {
    visibility: hidden;
    width: 430px;
    background-color: #f7f7f7;
    color: #5a5a5a;
    text-align: left;
    border-radius: 6px;
    padding: 5px;
    font-weight: normal !important;
    position: absolute;
    z-index: 5;
    font-size: 13px;
    text-transform: initial !important;
}

.dbInfotooltip:hover .dbInfotooltipText {
    visibility: visible;
}

.overrideTableBottom {
    /* margin-bottom: 0px !important;
    margin-bottom: 0px !important; */
}

.tooltip {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 3px;
    white-space: break-word;
    word-break: break-all;
    background-color: #00ABF3;
    color: #FFFFFF;
}

.historyYAxis text {
    transform: rotate(0deg) !important;
    -ms-transform: rotate(0deg) !important;
    /* IE 9 */
    -webkit-transform: rotate(0deg) !important;
    /* Safari 3-8 */
    font-size: 12px !important;
}

.historyXAxis text {
    font-size: 12px !important;
    text-anchor: end;
    /* transform: rotate(-40deg); */
    /* W3C */
    /* -webkit-transform: rotate(-40deg); */
    /* Safari & Chrome */
    /* -moz-transform: rotate(-40deg); */
    /* Firefox */
    /* -ms-transform: rotate(-40deg); */
    /* Internet Explorer */
    /* -o-transform: rotate(-40deg); */
    /* Opera */
}

.historyXAxis .label {
    font-size: 12px !important;
    transform: rotate(0deg) !important;
    /* W3C */
    -webkit-transform: rotate(0deg) !important;
    /* Safari & Chrome */
    -moz-transform: rotate(0deg) !important;
    /* Firefox */
    -ms-transform: rotate(0deg) !important;
    /* Internet Explorer */
    -o-transform: rotate(0deg) !important;
    /* Opera */
}

.createPoolBtn {
    background-color: #0073e7;
    font-size: 0.875rem;
    color: #fff;
    cursor: pointer;
    /* font-family: Metric; */
    font-weight: bold;
    padding: 6px;
}

.createPollWindowTitle {
    /* border-bottom: 1px solid #cccccc;
    background: #f7f7f7; */
    color: #323435;
    font-weight: 800 !important;
    font-size: 1.125rem;
    line-height: 1.23;
    padding: 10px;
}

.reservationMemTitle {
    /* font-family: Metric; 
    font-weight: bold;
    width: 100%;
    margin-bottom: 0px !important;*/
}

.reservationMembersTable {
    background: #e0dcdc;
    padding-top: 7px;
    padding-left: 10px;
    padding-bottom: 7px;
}

.reservationSearchFields {
    padding-left: 36px;
}

/**
Override to reduce row height in Table grid while using TrashIcon icon
*/
.grommetux-anchor__icon {
    display: inline-block;
    height: 0px !important;
    padding: 5px !important;
}

.productBaseFeatures>div:first-child,
.featuresBasedReservationtbl {
    max-height: 40vh;
    overflow-x: auto;
    /* width: 99%; */
}

.managePoolHeader {
    color: #0079EF;
    width: 9%;
    padding-left: 4px;
}

.managePoolHeadertd {
    width: 22%;
}

.allocatedCapcityTd {
    background-color: rgb(235, 235, 228);
    color: #000;
    border-bottom: 3px solid #fff !important;
    border-right: 3px solid #fff !important;
}

.allowBlockTd {
    padding: 4px;
    text-transform: uppercase;
    padding-left: 32px;
    background-color: #0073E7;
    display: block;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #ccc;
    color: #fff !important;
    font-size: 0.875rem;
    font-weight: 700;
}

.restricTd {
    text-align: center !important;
}

.reserveCapacityEdit {
    padding: 0px !important;
    border: 0px !important;
    border-bottom: 1px solid #ccc !important;
    width: 95%;
    padding-left: 4px !important;
    height: 24px;
}

.reserveCapacityDisabled {
    padding: 4px;
    background-color: rgb(235, 235, 228);
    display: block;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #ccc;
}

.allowBlockProductTd {
    text-transform: uppercase;
    border-bottom: 1px solid #ccc !important;
    font-size: 0.875rem;
    font-weight: 700;
}

.restricProductTd {
    text-align: left !important;
}

.productBasePools {
    /* height: 150px;
    overflow-x: auto; */
}

.productReserveCapacity {
    padding: 0px !important;
    border: 0px !important;
    border-bottom: 1px solid #ccc !important;
    width: 95%;
    padding-left: 4px !important;
    height: 24px;
}

.table-grid-radio-button {
    padding: 0px !important;
    margin-right: 0px !important;
    text-align: center;
}

.table-grid-radio-button label {
    padding-left: 10px;
}

.table-grid-checkbok-button {
    text-align: center;
}

.table-grid-checkbok-button label {
    padding-left: 10px;
}

.table-grid-th-checkbox label {
    padding-left: 10px;
}

.morePoolOptions {
    cursor: pointer;
}

.colorOption {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
}

/* #select-component-value-id .colorOption {
    margin-top: 0px !important;
} */


.more-all-groups {
    padding-left: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    background-color: rgb(241, 242, 243);
    margin-bottom: 2px;
}

.more-all-groups-display {
    padding-left: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    background-color: #FFFFFF;
    margin-bottom: 2px;
}

.more-all-pool-display {
    padding-left: 5px;
    padding-bottom: 6px;
    padding-top: 6px;
    border-bottom: 1px solid #ccc;
    background-color: #FFFFFF;
    margin-bottom: 2px;
    cursor: pointer;
}

.more-all-pool-display:hover {
    background-color: rgba(0, 115, 231, 0.05);
}

.usage-hub-sync-field {
    padding-left: 24px !important;
    color: rgba(0, 0, 0, 0.56) !important;
    font-size: 0.875rem !important;
    /* font-family: "Metric"; */
}

.fileFieldLabel label {
    position: absolute;
    cursor: pointer;
    border: 2px solid rgb(0, 115, 231);
    border-radius: 0px;
    font-size: 0.875rem;
    font-weight: 700;
    text-align: center;
    outline: none;
    padding: 6px 8px;
    letter-spacing: 0.5px;
    background-color: rgb(0, 115, 231);
    color: rgb(255, 255, 255);
}

.fileFieldName {
    color: rgba(0, 0, 0, 0.56) !important;
    font-size: 0.875rem !important;
    /* font-family: "Metric"; */
}

.invalilicenses {
    font-size: 0.875rem !important;
    text-transform: uppercase;
    /* font-family: Metric; */
    font-weight: bold;
    border-bottom: 1px solid #ccc;
    padding: 10px;
}

.poolwiseNA {
    font-size: 1.5rem !important;
    text-transform: uppercase;
    /* font-family: Metric; */
    padding-left: 70px;
}

.inuseActive {
    background-color: #F5F7F8;
    /*color: #FFFFFF;*/
    /* text-transform: capitalize !important; */
    /* font-family: Metric; */
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 9px !important;
}

.inuseActive button {
    height: 28px;
}

.inuseInActive button {
    height: 28px;
}

.inuseInActive {
    background-color: #F5F7F8;
    /*color: #FFFFFF;*/
    /* text-transform: capitalize !important; */
    /* font-family: Metric; */
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 9px !important;
}

.inuseActive label {
    /* text-transform: capitalize !important; */
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
}

.inuseInActive label {
    /* text-transform: capitalize !important; */
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: #5a5a5a;
}

.treegrid-p {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    color: black !important;
}

.subtab_title label {
    /* text-transform: capitalize !important; */
    font-weight: 500;
    font-size: 16px;
}

.tabActive label {
    /* text-transform: capitalize !important; */
    font-weight: 500;
    font-size: 16px;
}

.tabInActive label {
    /* text-transform: capitalize !important; */
    font-weight: 500;
    font-size: 16px;
}

.create-pool-modal {
    /* position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); */


    position: fixed;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    height: 100%;
    z-index: 10;
    background: rgba(0, 0, 0, 0.6);
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
}

input[type=text]::-ms-clear {
    display: none;
}

.tableRowStyle {
    cursor: default;
}

.clockTamperPopup {
    width: 192px;
    height: 30px;
    border: 1px solid #ff9283;
    border-radius: 15px;
    background-color: #fee4e7;
    left: 50%;
    top: 26px;
    float: right;
}

.clockTamperText {
    text-align: center;
    font-size: 13px;
    /* font-family: Metic_Regular; */
    color: #333;
    opacity: 0.8;
    vertical-align: middle;
}

.inuse-poolwise-tooltipContent {
    visibility: hidden;
    width: 15%;
    background-color: #555;
    color: #fff;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    left: 58%;
    margin-left: -50px;
    opacity: 0;
    top: 25%;
    transition: opacity 0.3s;
}

.inuse-poolwise-tooltipContentHide {
    content: "";
    position: absolute;
    top: 25%;
    left: 58%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.inuse-poolwise-tooltipContentshow {
    visibility: visible;
    opacity: 1;
}

.add-dep-feature-modal {
    position: fixed;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    height: 100%;
    z-index: 10;
    background: rgba(0, 0, 0, 0.6);
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
}

.tokenPopUp {
    background-color: #0073e7;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    /* font-family: Metric; */
    font-weight: bold;
    padding: 7px;
}

.captchacontainer {
    border: 2px solid rgba(109, 106, 106, 0.85);
    float: left;
    margin-right: 5px;
}

.captchaImage {
    display: block;
    width: 100%;
    height: auto;
}

.captchaoverlay {
    position: absolute;
    bottom: 0;
    /*  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);*/
    /* Black see-through */

    color: #f1f1f1;
    width: 100%;
    transition: .5s ease;
    opacity: 1;
    color: #323435;
    font-size: 20px;
    padding: 20px;
    text-align: center;
}

.Infotooltip {
    position: relative;
    display: inline-block;
    width: auto;
}

.InfotooltipText {
    visibility: hidden;
    width: max-content;
    background-color: #00ABF3;
    color: white;
    text-align: left;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    font-size: 13px;
    text-transform: initial !important;
    bottom: 150%;
    margin-left: -60px;
}

span.InfotooltipText.left {
    right: 0%;
}

span.InfotooltipText.right {
    left: 0%;
}

span.InfotooltipText.center {
    left: 50%;
    transform: translate(-50%, 0%);
}

.Infotooltip:hover .InfotooltipText {
    visibility: visible;
}

.InfotooltipText::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
}

.tokencaltable {
    padding-top: 7px;
    padding-left: 10px;
    padding-bottom: 7px;
    border: 5px solid #000;
}

.displayTokentable {
    /* font-family: Metric; */
    font-weight: bold;
    padding: 5px;
    float: left;
    width: 20%;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    text-align: left;
    text-transform: capitalize;
    background-color: #00ABF3;
    color: white;
}

.displayTokentableValue {
    /* font-family: Metric; */
    font-weight: bold;
    padding: 5px;
    float: left;
    width: 20%;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    text-align: left;
    text-transform: capitalize;

}

.displayTokentableValuelast {
    /* font-family: Metric; */
    font-weight: bold;
    padding: 5px;
    float: left;
    width: 20%;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    text-align: left;
    text-transform: capitalize;

}

#conf_idphostSelId {
    visibility: hidden;
    width: 10px;
}

#inuse_poolwise_graph_id .grommetux-value__annotated .grommetux-value__value {
    white-space: nowrap;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    font-size: 18px;
    padding-top: 23px;
}

/*#inuse_poolwise_graph_id .grommetux-value__annotated .grommetux-value__value:hover {
    white-space: normal; 
    text-overflow: clip; 
}*/
#inuse_checkout_type_graph_id .grommetux-value__annotated .grommetux-value__value {
    white-space: nowrap;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    font-size: 18px;
}

/*#inuse_checkout_type_graph_id .grommetux-value__annotated .grommetux-value__value:hover {
    white-space: normal; 
    text-overflow: clip; 
}*/
#inuse_checkout_type_graph_id .grommetux-value__annotated .grommetux-value__units {
    margin-left: 1.5rem;
    font-weight: 400;
    float: left;
}

#inuse_poolwise_graph_id .grommetux-value__annotated .grommetux-value__units {
    margin-left: 1.5rem;
    font-weight: 400;
    float: left;
}

.helpIconCls {
    border: 2px solid #FFFFFF;
    border-radius: 24px;
    float: right;
    width: 22px;
    height: 22px;
}

.helpIconCls svg {
    width: 15px !important;
    height: 15px !important;
    stroke-width: 3;
    position: relative;
    top: 4px;
    left: 4px;
}

.helpIconCls svg path {
    stroke-width: 3;
}

.avatarCls {
    outline: 0px solid transparent;
    border: 1px solid #656668;
    background-color: #ffffff;
    width: 350px;
    color: black;
    left: -293px;
    top: 47px;
    position: absolute;
    opacity: 0;
    z-index: 9999999;
}

.userTabTooltip {
    position: relative;
    display: flex;
    height: 55px;
    align-items: center;
}

.userTabTooltip .userTabTooltipText {
    visibility: hidden;
    width: 80px;
    background-color: white;
    color: black;
    text-align: center;
    padding: 4px 0;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -60px;
}

.userTabTooltip:hover .userTabTooltipText {
    visibility: visible;
}

#notificationDiv section {
    align-items: center;
    padding-top: 1px !important;
}

/* section {
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-right: 1px !important;
    padding-bottom: 0px !important;
} */

#notificationDiv button span {
    padding: 0px;
}

#notificationDiv button {
    height: 24px;
    padding: 0px !important;
}

#userManagement .grommetux-control-icon,
.grommetux-status-icon {
    width: 0px;
    height: 0px;
}

#userManagement .grommetux-control-icon--large {
    width: 23px !important;
    height: 22px !important;
}

#configuration .grommetux-control-icon,
.grommetux-status-icon {
    width: 0px;
    height: 0px;
}

#configuration .grommetux-control-icon--large {
    width: 23px !important;
    height: 23px !important;
}

#about .grommetux-control-icon,
.grommetux-status-icon {
    width: 0px;
    height: 0px;
}

#about .grommetux-control-icon--large {
    width: 23px !important;
    height: 26px !important;
}

#buymore .grommetux-control-icon,
.grommetux-status-icon {
    width: 0px;
    height: 0px;
}

#buymore .grommetux-control-icon--large {
    width: 23px !important;
    height: 26px !important;
}

.avtarTooltip {
    position: relative;
    display: inline-block;
}

.avtarTooltip .avtarTooltipText {
    visibility: hidden;
    width: auto;
    background-color: white;
    color: black;
    text-align: center;
    padding: 4px 4px 4px 4px;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -100px;
}

.avtarTooltip:hover .avtarTooltipText {
    visibility: visible;
}

.dropdown {
    float: left;
}

.dropdown-content {
    color: #323435 !important;
    position: absolute;
    z-index: 1;
    border: solid #ededed;
    -webkit-box-shadow: 2px 2px rgba(0, 0, 0, 0.07);
    box-shadow: 2px 2px rgba(0, 0, 0, 0.07);
    background-color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    width: auto;
    display: none;
}

.dropdown-content div a {
    text-decoration: none;
    text-align: left;
}

.dropdown-content div span svg {
    margin-top: 2px;
    margin-left: 2px;
}

.dropdown-content div:hover {
    background-color: rgba(0, 115, 231, .05);
}

.dropdown-content .active {
    background-color: rgba(0, 115, 231, .05);
}

.dropdown-content div {
    border-bottom: 1px solid #cccccc;
    padding: 6px;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.menu-icon-cls {
    /* float: left; */
    margin-right: 24px;
    /* margin-top: 7px; */

}

div#menuTitle>div {
    display: flex;
    align-items: center;
}

/* .logo_wrap {
    padding-right: 8px;
    border-right: 1px solid #fff;
} */

.slideMenu {
    color: #FFFFFF;
    position: absolute;
    z-index: 99999;
    background-color: #0073E7;
    width: 100%;
    top: 55px;
}

.dropdown-content-slide {
    color: #FFFFFF !important;
    z-index: 1;
    font-size: 0.82rem !important;
    font-weight: 400;
    cursor: pointer;
}

.dropdown-content-slide div {
    /* margin: 5px; */
}

.dropdown-content-slide a {
    color: #FFFFFF;
}

.dropdown-content-slide a:hover {
    color: rgba(255, 255, 255, 0.7);
}

.slide-menu-lable {
    cursor: pointer;
}

.slide-menu-lable:hover {
    color: rgba(255, 255, 255, 0.7);
}

.menu-label-div {
    /* margin-left: 21px; */
    margin-top: 0.7rem;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 17px;
    color: #FFFFFF;
    font-weight: 600;
    width: 100%;
}

.slide-menu-lable {
    padding-bottom: 6px;
}

#tabsWhite {
    margin: 0px !important;
}

#tabsWhite label {
    line-height: 20px;
}

#header2 {
    /* min-height: 34px; */
}

.license-div {
    background-color: #ffb000;
    width: 20%;
    height: 4px;
    margin-bottom: 12px;
}

#license:hover .license-div {
    background-color: #ffb000;
    width: 100%;
}

.reservation-div {
    background-color: #5bba36;
    width: 20%;
    height: 4px;
    margin-bottom: 12px;
}

#reservation:hover .reservation-div {
    background-color: #5bba36;
    width: 67%;
}

.remoteCommuter-div {
    background-color: #ba47e2;
    width: 20%;
    height: 4px;
    margin-bottom: 12px;
}

#remoteCommuter:hover .remoteCommuter-div {
    background-color: #ba47e2;
    width: 100%;
}

.usage-div {
    background-color: #2fd6c3;
    width: 20%;
    height: 4px;
    margin-bottom: 12px;
}

#usage:hover .usage-div {
    background-color: #2fd6c3;
    width: 100%;
}

table {
    margin-bottom: 0px !important;
}

.about-div-cls table {
    margin-bottom: 0px !important;
}

.about-div-cls table tbody tr td {
    padding: 0px !important;
}

.export-excel-cls svg {
    margin-top: 4px;
}

.colResizer {
    position: relative;
    border-color: #bdbec0;
    border-style: solid;
    border-width: 0 0 1px 1px;
    width: 3px;
    cursor: col-resize !important;
    padding: 0px;
}

.disableResizer {
    position: relative;
    border-left: 1px solid #cccccc;
    width: 1px;
    user-select: none;
}

.table-row-header {
    font-weight: bold;
    vertical-align: middle;
    border-color: #bdbec0;
    border-style: solid;
    border-width: 0 0 1px 0;
    padding: 5px;
    font-size: 0.82rem;
    text-align: start !important;
    letter-spacing: normal;
    color: #4c4e50;
    border-collapse: collapse;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.sectionCls {
    /* padding-left: 10px !important;
    padding-right: 10px !important; */
}

.tableBody {
    background-color: #FFFFFF;
    margin-top: 5px;
    margin-bottom: 5px;
    /* padding: 10px; */
}

.reservation-emtpy-message {
    padding: 5px;
    float: left;
}

.inuse-filter-table {
    float: right;
    width: 100%;
}

.inuse-filter-table td {
    padding: 0px !important;
}

.inuse-container {
    /* float: left;
    width: 100%; */
}

.inuse-container ul {
    margin: 0px !important;
    /* float: left; */
}

.chekout_type_tooltip {
    width: 94px;
    display: inline-block;
}

span[toolTipText]:hover::before {
    display: none;
    content: attr(toolTipText);
    position: absolute;
    left: 0%;
    top: -60%;
    background-color: #00ABF3;
    color: #FFFFFF;
    padding: 5px;
    border: 1px solid #cccccc;
    border-radius: 5px;
}

td[toolTipText]:hover::before {
    content: attr(toolTipText);
    position: absolute;
    background-color: #00ABF3;
    color: #FFFFFF;
    padding: 5px;
    border: 1px solid #cccccc;
    margin-top: 25px;
    z-index: 1;
    margin-left: 60px;
}


div[toolTipText]:hover::before {
    /* display: none; */
    content: attr(toolTipText);
    position: absolute;
    padding: 4px;
    background-color: #FFFFFF;
    border: 1px solid #cccccc;
    margin-top: 20px;
    z-index: 1;
    margin-left: 10px;
}



.jobs-popup-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.pool-import-history svg {
    cursor: pointer;
}

.aptable-pagination {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    font-size: .85rem !important;
}

.inuse-title {
    color: #5a5a5a;
    font-size: 19px;
    width: 40%;
    float: left;
    /* margin-bottom: 5px; */
    margin-left: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 30px;
}

.table-filter-td input {
    padding: 2px !important;
    width: 97%;
    height: 1.9rem;
}

.appaginationPageSize input {
    padding: 2px !important;
    width: 50px;
    text-align: center;
}

.appagination {
    padding-right: 10px;
    width: 100px;
    text-align: center;
    position: relative;
}

.appagination::after {
    content: "/";
    position: absolute;
    right: 2px;
    top: 3px;
    font-size: 22px;
}

.appagination input {
    text-align: center;
}

.config-cont-backgroud {
    background-color: #f6f6f6;
    display: block;
    float: left;
    width: 100%;
    padding: 10px !important;
    margin-top: 40px;
    box-sizing: border-box;
}

.pivot-cont-backgroud {
    background-color: #FFFFFF;
    border: 1px solid #ddd;
    padding-right: 3px;
    padding-bottom: 5px;
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 40px;
}

#usage_list_form_seletDateRange .grommetux-form-field__label {
    width: auto;
    line-height: 29px;
}

.inuse-filter-td {
    padding: 0px !important;
}

.select-drop-down {
    /* border: 1px solid #f1efef; */
    /* padding: 5px; */
    /* float: left;
    display: flex; */
    /* margin-top: 5px; */
    /* background-color: #FFFFFF;
    cursor: pointer; */
    /* margin-right: 10px; */
    position: relative;
}

.notification-message {
    vertical-align: middle;
    min-width: 40%;
    max-width: 80%;
    width: auto;
    text-align: center;
    min-height: 10px;
    height: auto;
    overflow-wrap: break-word;
    z-index: 998;
    top: 12px;
    left: 30%;
    position: fixed;
}

.notification-message-comp {
    vertical-align: middle;
    min-width: 80%;
    max-width: 80%;
    width: auto;
    text-align: center;
    min-height: 10px;
    height: auto;
    overflow-wrap: break-word;
    z-index: 9999;
    top: 5px;
    left: 10%;
    position: fixed;
}

.notification-message-comp button {
    height: 26px;
    padding: 0px !important;
}

.notification-message-comp button span {
    padding: 0px !important;
}

.notification-message-comp .grommetux-notification__message {
    padding-left: 2px;
}

.critical.notification-message-comp>#message-id {
    background: #e5004c;
}

.normal.notification-message-comp>#message-id {
    background-color: rgb(26, 172, 96);
}


.notification-message-comp>#message-id p[class^="StyledParagraph"] {
    margin: 0px !important;
}

.notification-message-comp>#message-id p[class^="StyledParagraph"] span {
    font-size: 0.8rem;
}

.critical.notification-message-comp>#message-id p[class^="StyledParagraph"] span {
    color: #ffffff;
}

.normal.notification-message-comp>#message-id p[class^="StyledParagraph"] span {
    color: #ffffff;
}

.notification-message-comp>#message-id button[class^="StyledButton"] svg {
    fill: #ffffff !important;
    stroke: #ffffff !important;
}

#intanceon-div-id .tableBody {
    margin-top: 0px;
}

.license-report-search-popup {
    z-index: 111;
    position: absolute;
    background-color: #FFFFFF;
    right: 40px;
    top: 0px;
    border: 1px solid #ddd;
}

.select-options-div {
    z-index: 1;
}

.reservation-pool-attributes {
    /* float: left;
    background-color: #F5F7F8;
    border: 1px solid #cccccc;
    margin: 0px 0px 6px 0px;
    width: 100%; */
}

#resv_member_tabs_div_id ul {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.reservation-attribute-tab {
    /* background-color: #FFFFFF;
    border: 1px solid #cccccc !important;
    float: left;
    margin: 5px 2px 2px 2px; */
}

#resv_user_name_table_id .grommetux-form-field,
#resv_host_table_id .grommetux-form-field,
#resv_client_table_id .grommetux-form-field,
#resv_ip_address_table_id .grommetux-form-field {
    padding: 5px 5px;
}

#resv_add_user_id,
#resv_ipaddress_user_id,
#resv_hostid_user_id,
#resv_clientid_user_id {
    height: 32px !important;
}

.multi-slection-options-li {
    font-size: 0.82rem !important;
    font-weight: 400;
    line-height: 1.375;
    color: rgba(0, 0, 0, 0.56);
    color: #323435;
    padding: 6px 12px;
}

.multi-selection-options-buttons {
    float: left;
    padding: 10px;
    border-top: 1px solid #cccc;
    /* width: 100%; */
    padding-top: 10px;
}

.select-ok-button {
    text-transform: uppercase !important;
}

.bodyBackGroudColor {
    background-color: #F5F7F8;
}

.user-details-div {
    display: flex;
    /* float: left; */
    /* background-color: rgb(255, 255, 255); */
    width: 98.5%;
    margin: 0px 25px 10px 0px;
    /* margin-bottom: 4px; */
    /* border: 1px solid #cccccc; */
    /* margin-top: 7px; */
}

#search-span-id input,
#group-associate-id input {
    height: 30px;
}

.maxHeight-scroll {
    max-height: 450px;
    overflow: auto;
}

#pool-attributes-search-div .grommetux-search--inline {
    color: #323435;
    min-width: 210px;
}

#productReportSearchDiv .grommetux-search--inline {
    color: #323435;
    min-width: -webkit-fill-available;
}

.inuse-cont-backgroud {
    background-color: #FFFFFF;
    border: 1px solid #ddd;
    /* padding-right: 3px; */
    padding-bottom: 5px;
    /* margin-left: 3px;
    margin-right: 3px; */
}

.react-toggle .react-toggle-track {
    width: 22px;
    height: 15px;
    /*background-color: #c1c0c0;*/
}

.toggle-classname.react-toggle--checked .react-toggle-track {
    background-color: #0073E7;
}

.react-toggle--checked .react-toggle-thumb {
    left: 9px;
    border-color: #FFFFFF;
    border: 0px;
}

.react-toggle-thumb {
    -webkit-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    -o-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 2px;
    left: 1px;
    width: 11px;
    height: 11px;
    border: 1px solid #4D4D4D;
    border-radius: 50%;
    background-color: #FAFAFA;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.back-button-container {
    background-color: rgb(204 204 204 / 40%);
    float: left;
    padding: 9px 10px;
    cursor: pointer;
    align-items: center;
    display: flex;
}

.back-button-container:hover {
    background-color: rgb(204 204 204 / 100%);
}

.back-button-container .grommetux-control-icon--huge {
    width: 30px;
    height: 30px;
}

/* .bodyfont table>thead>tr>th,
.bodyfont table>tbody>tr>th,
.bodyfont table>tfoot>tr>th,
.bodyfont table>thead>tr>td,
.bodyfont table>tbody>tr>td,
.bodyfont table>tfoot>tr>td {
    word-wrap: break-word;
    font-size: 0.82rem !important;
    padding: 5px !important;
} */

.grommetux-select__drop .grommetux-select__option {
    font-size: 0.82rem !important;
    font-weight: 400;
    line-height: 1.375;
    color: rgba(0, 0, 0, 0.56);
    color: #323435;
    padding: 5px 24px;
}

.grommetux-button:not(.grommetux-button--box) {
    padding: 8px !important;
    margin: 0px !important;
}

.grommet p:not(.grommetux-paragraph) {
    max-width: 576px;
    margin-left: 0px;
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.375;
    color: rgba(0, 0, 0, 0.56);
}

.grommet {
    font-size: 14px !important;
    line-height: 19px;
    letter-spacing: 0.2px;
}

.appagination .grommetux-select__control .grommetux-button__icon {
    height: 20px !important;
}

#tabsWhite li {
    padding: 0px;
    padding-left: 3px;
}

button span {
    text-transform: none !important;
}

#lic_bor_borrowBtn span {
    text-transform: none !important;
}

.grommet h2 {
    font-weight: 700;
    font-style: normal;

    letter-spacing: 1.1px;
}

.alert-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
}

.table-grid-tr {
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    line-height: 19px;
}

.grommetux-date-time .grommetux-button:not(.grommetux-button--box) {
    padding: 0px !important;
}

.grid-cell-value-h1 {
    font-size: 0.82rem !important;
    text-transform: none !important;
    letter-spacing: 0.25px !important;
    padding: 0px !important;
    margin: 0px !important;
    letter-spacing: 0.2px !important;
}

.sticky-header {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 10;
}

.sub_menu_wrap {
    position: fixed;
    top: 55px;
    width: 100%;
    z-index: 1;
}

#apikeyexpiredivid .grommetux-form-field__error {
    left: 5%;
}

#apikeydivid .grommetux-form-field__error {
    left: 5%;
}

.configEntryEdit {
    padding: 0px !important;
    border: 0px !important;
    border-bottom: 1px solid #ccc !important;
    width: 50%;
    padding-left: 4px !important;
    height: 24px;
}

.datespop-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000 !important;
    background: rgba(0, 0, 0, 0.6);
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .grommetux-radio-button__control {
        top: 7px;
    }
}

.blink {
    animation: blinker 10s linear infinite;
    color: #0073E7;
    /* font-family: cursive; */
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

#logoutButton span {
    white-space: nowrap;
}

.inline-flex-wrap {
    display: flex;
    justify-content: flex-start;
    padding-left: 20px;
    margin-bottom: 20px;
}



.copiedTooltip .copiedTooltipText {
    visibility: hidden;
    width: 80px;
    border: 1px solid #4D4D4D;
    background-color: white;
    color: black;
    text-align: center;
    padding: 4px 0;
    position: absolute;
    z-index: 1;
}

a[class^="StyledAnchor"] svg {
    stroke: #0073E7;
}

.company-logo.company-logo-image2 {
    background-repeat: no-repeat;
    background-size: 80px 25px;
    background-image: url("../img/OT_grey.png");
    height: 30px;
}

#conf_ct_tenant_idm_formId>table,
#conf_ct_asset_idm_formId>table {
    width: 69%;
    table-layout: auto !important;
}

#conf_ct_tenant_idm_formId>table .grommetux-form-field .grommetux-form-field__label,
#conf_ct_asset_idm_formId>table .grommetux-form-field .grommetux-form-field__label {
    width: 230px;
    float: left;
    margin-right: 5px;
}

.apfiletext {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
    max-width: 90%;
}






form#conf_main_form_id {
    display: flex;
    flex-flow: wrap;
}

.left_grid {
    flex: 0 0 49%;
    position: relative;
}

.left_grid::after {
    content: "";
    /* border-right: 1px solid rgba(0, 0, 0, 0.2); */
    position: absolute;
    right: 0;
    top: 0px;
    width: 1px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
}

div#conf_hostIpGrid {
    /* padding-right: 8px; */
}

.right_grid {
    flex: 0 0 49%;
}

.right_grid>* {
    padding-left: 10px;
}

.saml_conf_wrap div.dropdown_select {
    margin: 0 !important;
}

.grommetux-password-input.password_icon *[aria-label="Show Password"] {
    position: absolute;
    padding: 0px;
    right: 5px;
    top: 4px;
}

.modal-main.panel.panel-default {
    /* min-width: 50%;
    max-width: 75%; */
    display: flex;
    padding: 10px;
    flex-direction: column;
}

#tokenMainDivId .modal-main.panel.panel-default {
    flex-direction: column;
}

.cursor-pointer {
    cursor: pointer;
}