.headerOptions {
  float: right;
  padding-right: 10px;
}

.headerOptions .options {
  float: left;
  padding: 8px 16px;
  border-bottom: 1px solid white;
}

.headerOptions .options:hover {
  border-bottom: 1px solid gray;
}

tr.table_header_row {
  >th {
    border-bottom: 1px solid #bdbec0;
  }
}

.table {
  table-layout: fixed;
}

.grommetux-table table {
  table-layout: fixed;
}