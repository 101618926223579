.pagination_wrapper {
    .pagination_left {
        flex-wrap: wrap;

        .appaginationPageSize {
            display: flex;
            flex-direction: row;
            align-content: center;

            .no_of_records {
                align-content: center;
                flex-wrap: wrap;
                display: ruby;
            }

            div {
                text-wrap: nowrap;
                margin-right: 10px;
            }

        }

        .appageList {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            flex-direction: row;
            margin-left: 10px;
        }

        .apPaginationNavigation {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-content: center;

            a {
                border: 1px solid rgba(0, 0, 0, 0.3);
                display: flex;
                height: 24px;

                svg {
                    fill: #0073e7;
                    stroke: #0073e7;
                    width: 18px;
                }

                svg:nth-child(2) {
                    margin-left: -12px;
                }
            }
        }
    }

    .pagination_right {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;

        .appagination {
            input[class*="StyledTextInput"] {
                padding: 4px;
                border-right: 1px solid rgba(0, 0, 0, 0.3);
                border-radius: 0px !important;
            }

            .kpKaew {
                margin: 0px;
            }

            svg {
                stroke: #0073e7;
                fill: #0073e7;
            }
        }

        .appaginationTotalpages {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            span:first-child {
                padding-right: 3px;
            }
        }
    }

}