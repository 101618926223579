.pageList {
    float: left;
    font-weight: 700;
    padding-left: 10px;
}

.pagination {
    float: right;
    padding-right: 10px;
}

.pagination a {
    color: black !important;
    float: left;
    padding: 8px 16px;
    text-decoration: none !important;
    border: 1px solid #ddd;
}

.pagination a.active {
    background-color: rgba(0, 0, 0, 0.493);
    color: white;
    border: 1px solid rgba(0, 0, 0, 0.493);
}

.pagination a:hover:not(.active) {
    background-color: #ddd;
}

.pagination a:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.pagination a:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}